import axios from "axios"



const sharedApi = axios.create({
    baseURL: import.meta.env.VITE_SERVER_ROOT,
    timeout: 10000

})


export default {
    async postCreateSubscriber(email) {
        return sharedApi.post(
            "/shared/subscribers",
            { email: email },
            {
                headers: {
                    'x-app-id': import.meta.env.VITE_APP_ID,
                }
            }
        )
    },
    async deleteRemoveSubscriber(unsubCode) {
        return sharedApi.delete(
            "/shared/subscribers",
            {
                headers: {
                    'x-app-id': import.meta.env.VITE_APP_ID,
                },
                data: { unsub_code: unsubCode },
            }
        )
    },
    async getLocationFromApi() {
        try {
            console.info("%cWallcharts.org does not log or record your IP in any form, nor track you in any way using your IP. It is used to set your chart timezone only as part of the core purpose of the site. Network request details:", "color: green; font-weight: bold;", `${sharedApi.defaults.baseURL}/shared/this-endpoint-does-not-store-your-ip`)
            const resp = await sharedApi.get(
                `/shared/this-endpoint-does-not-store-your-ip`,
            )
            console.info("%cThe only location data used:", "color: green; font-weight: bold;", resp.data.data)
            return resp.data.data
        } catch (error) {
            console.error("Error fetching location info:", error)
        }
    },
    async getMetadata(url) {
        return sharedApi.get(
            `/shared/metadata/?url=${encodeURIComponent(url)}`,
            {
                headers: {
                    'x-app-id': import.meta.env.VITE_APP_ID,
                    'Content-Type': 'application/json'
                }
            }
        )
    },
}
