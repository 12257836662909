export default {
  "en": {
    "HeroTopLine": {
      "content": "Free. Print-at-home. Customisable.",
      "aria": "Customise your free, print-at-home wallcharts",
      "contextForGPT": {
        "purpose": "to convey that the chart is user customizable, it is free and designed to be printed at home on a home printer",
        "action": "no action",
        "audience": "user visiting site likely for the first time to create a chart"
      }
    },
    "HeroTopLine2": {
      "content": "Your timezone. Your language.",
      "aria": "your timezone and your language",
      "contextForGPT": {
        "purpose": "users can set the match kick off times to their timezone and have the charts in their language",
        "action": "no action",
        "audience": "user visiting site likely for the first time to create a chart, language may need gendered if the competition is for women?"
      }
    },
    "HeroMidLine": {
      "content": "Euro 2025 Wallcharts",
      "aria": "Euro 2025 Wallcharts",
      "contextForGPT": {
        "purpose": "these wallcharts are for the womens female 2025 european soccer championships",
        "action": "no action",
        "audience": "user visiting site likely for the first time to create a chart, language may need gendered if the competition is for women?"
      }
    },
    "HeroSupportingLine": {
      "content": "supporting",
      "aria": "supporting",
      "contextForGPT": {
        "purpose": "this line indicates which charity that wallcharts.org this website is supporting and hopeing to raise money for. indicates the charitable objective of the site",
        "action": "no action",
        "audience": "user visiting site likely for the first time to create a chart, language may need gendered if the competition is for women?"
      }
    }
  },
  "da": {
    "HeroTopLine": {
      "content": "Gratis. Print derhjemme. Tilpasningsdygtig.",
      "aria": "Tilpas dine gratis, print-hjemme vægskemaer"
    },
    "HeroTopLine2": {
      "content": "Din tidszone. Dit sprog.",
      "aria": "din tidszone og dit sprog"
    },
    "HeroMidLine": {
      "content": "Euro 2025 Vægskema",
      "aria": "Euro 2025 Vægskema"
    },
    "HeroSupportingLine": {
      "content": "støtter",
      "aria": "støtter"
    }
  },
  "nl": {
    "HeroTopLine": {
      "content": "Gratis. Print thuis. Aanpasbaar.",
      "aria": "Pas je gratis, thuis te printen wandplanners aan"
    },
    "HeroTopLine2": {
      "content": "Jouw tijdzone. Jouw taal.",
      "aria": "jouw tijdzone en jouw taal"
    },
    "HeroMidLine": {
      "content": "Euro 2025 Wandplanner",
      "aria": "Euro 2025 Wandplanner"
    },
    "HeroSupportingLine": {
      "content": "ondersteunt",
      "aria": "ondersteunt"
    }
  },
  "fi": {
    "HeroTopLine": {
      "content": "Ilmainen. Tulosta kotona. Muokattavissa.",
      "aria": "Mukauta ilmaisia, kotona tulostettavia seinätaulukoita"
    },
    "HeroTopLine2": {
      "content": "Sinun aikavyöhykkeesi. Sinun kielesi.",
      "aria": "sinun aikavyöhykkeesi ja kielesi"
    },
    "HeroMidLine": {
      "content": "Euro 2025 Seinätaulukko",
      "aria": "Euro 2025 Seinätaulukko"
    },
    "HeroSupportingLine": {
      "content": "tukee",
      "aria": "tukee"
    }
  },
  "fr": {
    "HeroTopLine": {
      "content": "Gratuit. Imprimable à la maison. Personnalisable.",
      "aria": "Personnalisez vos calendriers muraux gratuits, imprimables à domicile"
    },
    "HeroTopLine2": {
      "content": "Votre fuseau horaire. Votre langue.",
      "aria": "votre fuseau horaire et votre langue"
    },
    "HeroMidLine": {
      "content": "Calendrier mural Euro 2025",
      "aria": "Calendrier mural Euro 2025"
    },
    "HeroSupportingLine": {
      "content": "soutenant",
      "aria": "soutenant"
    }
  },
  "de": {
    "HeroTopLine": {
      "content": "Kostenlos. Zuhause druckbar. Anpassbar.",
      "aria": "Passen Sie Ihre kostenlosen, zuhause druckbaren Wandkalender an"
    },
    "HeroTopLine2": {
      "content": "Ihre Zeitzone. Ihre Sprache.",
      "aria": "Ihre Zeitzone und Ihre Sprache"
    },
    "HeroMidLine": {
      "content": "Euro 2025 Wandkalender",
      "aria": "Euro 2025 Wandkalender"
    },
    "HeroSupportingLine": {
      "content": "unterstützend",
      "aria": "unterstützend"
    }
  },
  "is": {
    "HeroTopLine": {
      "content": "Ókeypis. Prenta heima. Aðlögunarhæft.",
      "aria": "Sérsníddu ókeypis veggspjöld sem má prenta heima"
    },
    "HeroTopLine2": {
      "content": "Tímabelti þitt. Tungumál þitt.",
      "aria": "tímabeltið þitt og tungumálið þitt"
    },
    "HeroMidLine": {
      "content": "Euro 2025 Veggspjald",
      "aria": "Euro 2025 Veggspjald"
    },
    "HeroSupportingLine": {
      "content": "styður",
      "aria": "styður"
    }
  },
  "it": {
    "HeroTopLine": {
      "content": "Gratuito. Stampabile a casa. Personalizzabile.",
      "aria": "Personalizza i tuoi calendari murali gratuiti stampabili a casa"
    },
    "HeroTopLine2": {
      "content": "Il tuo fuso orario. La tua lingua.",
      "aria": "il tuo fuso orario e la tua lingua"
    },
    "HeroMidLine": {
      "content": "Calendario da parete Euro 2025",
      "aria": "Calendario da parete Euro 2025"
    },
    "HeroSupportingLine": {
      "content": "supportando",
      "aria": "supportando"
    }
  },
  "no": {
    "HeroTopLine": {
      "content": "Gratis. Print hjemme. Tilpassbart.",
      "aria": "Tilpass dine gratis, hjemmeprintede veggkart"
    },
    "HeroTopLine2": {
      "content": "Din tidssone. Ditt språk.",
      "aria": "din tidssone og ditt språk"
    },
    "HeroMidLine": {
      "content": "Euro 2025 Veggkart",
      "aria": "Euro 2025 Veggkart"
    },
    "HeroSupportingLine": {
      "content": "støtter",
      "aria": "støtter"
    }
  },
  "pl": {
    "HeroTopLine": {
      "content": "Darmowe. Do druku w domu. Dostosowywalne.",
      "aria": "Dostosuj swoje darmowe, do druku w domu wykresy ścienne"
    },
    "HeroTopLine2": {
      "content": "Twoja strefa czasowa. Twój język.",
      "aria": "twoja strefa czasowa i twój język"
    },
    "HeroMidLine": {
      "content": "Euro 2025 Kalendarz ścienny",
      "aria": "Euro 2025 Kalendarz ścienny"
    },
    "HeroSupportingLine": {
      "content": "wspierając",
      "aria": "wspierając"
    }
  },
  "pt": {
    "HeroTopLine": {
      "content": "Grátis. Imprima em casa. Personalizável.",
      "aria": "Personalize seus gráficos de parede gratuitos, imprimíveis em casa"
    },
    "HeroTopLine2": {
      "content": "Seu fuso horário. Seu idioma.",
      "aria": "seu fuso horário e seu idioma"
    },
    "HeroMidLine": {
      "content": "Calendário de Parede Euro 2025",
      "aria": "Calendário de Parede Euro 2025"
    },
    "HeroSupportingLine": {
      "content": "apoiando",
      "aria": "apoiando"
    }
  },
  "es": {
    "HeroTopLine": {
      "content": "Gratis. Imprime en casa. Personalizable.",
      "aria": "Personaliza tus calendarios de pared gratuitos, imprimibles en casa"
    },
    "HeroTopLine2": {
      "content": "Tu zona horaria. Tu idioma.",
      "aria": "tu zona horaria y tu idioma"
    },
    "HeroMidLine": {
      "content": "Calendario de pared Euro 2025",
      "aria": "Calendario de pared Euro 2025"
    },
    "HeroSupportingLine": {
      "content": "apoyando",
      "aria": "apoyando"
    }
  },
  "sv": {
    "HeroTopLine": {
      "content": "Gratis. Skriv ut hemma. Anpassningsbar.",
      "aria": "Anpassa dina gratis väggkalendrar för utskrift hemma"
    },
    "HeroTopLine2": {
      "content": "Din tidszon. Ditt språk.",
      "aria": "din tidszon och ditt språk"
    },
    "HeroMidLine": {
      "content": "Euro 2025 Väggkalender",
      "aria": "Euro 2025 Väggkalender"
    },
    "HeroSupportingLine": {
      "content": "stöder",
      "aria": "stöder"
    }
  },
  "cy": {
    "HeroTopLine": {
      "content": "Am ddim. Argraffu gartref. Addasadwy.",
      "aria": "Addaswch eich siartiau wal am ddim, sy'n gallu cael eu hargraffu gartref"
    },
    "HeroTopLine2": {
      "content": "Eich parth amser. Eich iaith.",
      "aria": "eich parth amser a'ch iaith"
    },
    "HeroMidLine": {
      "content": "Siart Wal Euro 2025",
      "aria": "Siart Wal Euro 2025"
    },
    "HeroSupportingLine": {
      "content": "cefnogi",
      "aria": "cefnogi"
    }
  }
}
