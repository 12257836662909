<template>


        <div class=" flex flex-col w-full   items-center justify-center space-y-10 mt-16 ">

            <h1 class=" text-3xl  items-center  justify-center text-center text-sky-800">404</h1>
            <h1 class=" text-lg sm:text-2xl items-center  justify-center text-center text-sky-800">
                <router-link class="px-4 py-2 bg-sky-800 rounded-md text-white font-medium" :to="{name: 'root'}">Let's go home!</router-link></h1>

        </div>

</template>

<script setup>



</script>