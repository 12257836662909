<script setup>
import { ref } from 'vue'

import BracketChart from './BracketChart.vue'
import ScheduleChart from './ScheduleChart.vue'
import FactsChart from './FactsChart.vue'
import GamesChart from './GamesChart.vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { useChartStore } from '../../stores/chart.js'
const chart = useChartStore()


const inView = ref('Bracket')

const pages = ['Bracket', 'Schedule']

function selectPage(page) {
  inView.value = page
}
</script>

<template>
  <div class="flex flex-col relative items-center justify-center w-full h-full">
    <button v-if="!chart.chartId" @click="chart.createChart(); ui.closeRightTray()"
      class="shadow-lg absolute px-3 -top-14  font-medium text-base bg-sky-800 text-white h-10 w-52 rounded-md border border-sky-800">
      Create
    </button>
    <button v-else @click="chart.clearChartState(); ui.closeRightTray()"
      class="shadow-lg absolute px-3 -top-14 font-medium text-base bg-white text-sky-800   h-10 w-52 rounded-md border border-sky-800">
      Make your own chart
    </button>
    <div id="page-selector" class=" flex items-center justify-evenly space-x-4 w-full mt-4">
      <button v-for="(page, index) in pages" :key="index" :class="[
        'px-2 py-0.5 text-xs sm:text-sm rounded-sm ',
        inView === page ? 'bg-sky-800 text-white shadow-md' : 'bg-white text-sky-800'
      ]" @click="selectPage(page)">
        {{ page }}
      </button>
    </div>

    <div class="w-full h-full flex items-start justify-start lg:justify-center overflow-auto">
      <div class="w-[1122px] h-[794px] min-w-[1122px] min-h-[794px] flex-shrink-0"
        style="position: relative; top: 0; left: 0;">
        <BracketChart v-if="inView === 'Bracket'" class="border border-gray-400 shadow-xl rounded-xl p-2 m-4" />
        <ScheduleChart v-if="inView === 'Schedule'" class="border border-gray-400 shadow-xl rounded-xl p-2 m-4" />
        <FactsChart v-if="inView === 'Facts'" class="border border-gray-400 shadow-xl rounded-xl p-2 m-4" />
        <GamesChart v-if="inView === 'Games'" class="border border-gray-400 shadow-xl rounded-xl p-2 m-4" />
      </div>
    </div>
  </div>
</template>
