<script setup>
import { onMounted, ref, watch, computed } from 'vue'

import { useChartStore } from '../../stores/chart.js'
const chart = useChartStore()


const qrLayoutStyles = {
    'cascade_ltr_landscape': {
        goodCause: "position: absolute; bottom: 28px; right: 60px;  height: 100px; width: 100px",
        commercial: "position: absolute; bottom: 28px; right: 230px;  height: 100px; width: 100px"
    }
}


</script>


<template>
    <div class="w-full  flex flex-col justify-start  items-center ">
        <div id="chart-wrapper-only" class=" rounded-md  bg-white border border-gray-500  w-full">
            Facts placeholder

        </div>
    </div>

</template>


