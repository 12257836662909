import { ref, watch, computed } from "vue"
import { defineStore } from "pinia"
import sharedApi from '../api/sharedApi.js'
import { DateTime } from 'luxon'
import moment from 'moment-timezone';

export const useLocationStore = defineStore('locationStore', () => {

    const deviceCountry = ref(null)
    // const deviceCountryCode = ref(null)
    // const deviceRegion = ref(null)
    // const deviceRegionName = ref(null)
    // const deviceCity = ref(null)
    const deviceTimezone = ref(null)
    // const deviceOffset = ref(null)

    const selectedTimezone = ref(null) //supportedTimezones.zone


    // CURRENT AT JAN 2025
    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async function getLocation() {
        const timezonePref = localStorage.getItem('timezonePref')

        if (timezonePref) {
            selectedTimezone.value = timezonePref
            console.info("%cYour timezone preference was already set, using that :)", "color: green; font-weight: normal;")

        } else {
            await delay(1000)
            const locationResp = await sharedApi.getLocationFromApi()
            if (locationResp) {
                deviceCountry.value = locationResp.country
                // deviceCountryCode.value = locationResp.countryCode
                // deviceRegion.value = locationResp.region
                // deviceRegionName.value = locationResp.regionName
                // deviceCity.value = locationResp.city
                deviceTimezone.value = locationResp.timezone
                // deviceOffset.value = locationResp.offset
                selectedTimezone.value = locationResp.timezone
            } else {
                deviceCountry.value = "United Kingdom"
                // deviceCountryCode.value = "GB"
                // deviceRegion.value = "ENG"
                // deviceRegionName.value = "England"
                // deviceCity.value = "Manchester"
                deviceTimezone.value = "Europe/London"
                // deviceOffset.value = 3600
                selectedTimezone.value = 'Europe/London'
            }
        }
    }

    watch(selectedTimezone, (newVal, oldVal) => {
        if (newVal != oldVal) {
            localStorage.setItem('timezonePref', newVal)
        }
    })


    const supportedTimezones = [
        {zone:'Africa/Abidjan',region:'Africa',locale:'Abidjan',},
        {zone:'Africa/Accra',region:'Africa',locale:'Accra',},
        {zone:'Africa/Addis_Ababa',region:'Africa',locale:'Addis_Ababa',},
        {zone:'Africa/Algiers',region:'Africa',locale:'Africa',},
        {zone:'Africa/Asmara',region:'Africa',locale:'Asmara',},
        {zone:'Africa/Asmera',region:'Africa',locale:'Asmera',},
        {zone:'Africa/Bamako',region:'Africa',locale:'Bamako',},
        {zone:'Africa/Bangui',region:'Africa',locale:'Bangui',},
        {zone:'Africa/Banjul',region:'Africa',locale:'Banjul',},
        {zone:'Africa/Bissau',region:'Africa',locale:'Bissau',},
        {zone:'Africa/Blantyre',region:'Africa',locale:'Blantyre',},
        {zone:'Africa/Brazzaville',region:'Africa',locale:'Brazzaville',},
        {zone:'Africa/Bujumbura',region:'Africa',locale:'Bujumbura',},
        {zone:'Africa/Cairo',region:'Africa',locale:'Cairo',},
        {zone:'Africa/Casablanca',region:'Africa',locale:'Casablanca',},
        {zone:'Africa/Ceuta',region:'Africa',locale:'Ceuta',},
        {zone:'Africa/Conakry',region:'Africa',locale:'Conakry',},
        {zone:'Africa/Dakar',region:'Africa',locale:'Dakar',},
        {zone:'Africa/Dar_es_Salaam',region:'Africa',locale:'Dar_es_Salaam',},
        {zone:'Africa/Djibouti',region:'Africa',locale:'Djibouti',},
        {zone:'Africa/Douala',region:'Africa',locale:'Douala',},
        {zone:'Africa/El_Aaiun',region:'Africa',locale:'El_Aaiun',},
        {zone:'Africa/Freetown',region:'Africa',locale:'Freetown',},
        {zone:'Africa/Gaborone',region:'Africa',locale:'Gaborone',},
        {zone:'Africa/Harare',region:'Africa',locale:'Harare',},
        {zone:'Africa/Johannesburg',region:'Africa',locale:'Johannesburg',},
        {zone:'Africa/Juba',region:'Africa',locale:'Juba',},
        {zone:'Africa/Kampala',region:'Africa',locale:'Kampala',},
        {zone:'Africa/Khartoum',region:'Africa',locale:'Khartoum',},
        {zone:'Africa/Kigali',region:'Africa',locale:'Kigali',},
        {zone:'Africa/Kinshasa',region:'Africa',locale:'Kinshasa',},
        {zone:'Africa/Lagos',region:'Africa',locale:'Lagos',},
        {zone:'Africa/Libreville',region:'Africa',locale:'Libreville',},
        {zone:'Africa/Lome',region:'Africa',locale:'Lome',},
        {zone:'Africa/Luanda',region:'Africa',locale:'Luanda',},
        {zone:'Africa/Lubumbashi',region:'Africa',locale:'Lubumbashi',},
        {zone:'Africa/Lusaka',region:'Africa',locale:'Lusaka',},
        {zone:'Africa/Malabo',region:'Africa',locale:'Malabo',},
        {zone:'Africa/Maputo',region:'Africa',locale:'Maputo',},
        {zone:'Africa/Maseru',region:'Africa',locale:'Maseru',},
        {zone:'Africa/Mbabane',region:'Africa',locale:'Mbabane',},
        {zone:'Africa/Mogadishu',region:'Africa',locale:'Mogadishu',},
        {zone:'Africa/Monrovia',region:'Africa',locale:'Monrovia',},
        {zone:'Africa/Nairobi',region:'Africa',locale:'Nairobi',},
        {zone:'Africa/Ndjamena',region:'Africa',locale:'Ndjamena',},
        {zone:'Africa/Niamey',region:'Africa',locale:'Niamey',},
        {zone:'Africa/Nouakchott',region:'Africa',locale:'Nouakchott',},
        {zone:'Africa/Ouagadougou',region:'Africa',locale:'Ouagadougou',},
        {zone:'Africa/Porto-Novo',region:'Africa',locale:'Porto-Novo',},
        {zone:'Africa/Sao_Tome',region:'Africa',locale:'Sao_Tome',},
        {zone:'Africa/Timbuktu',region:'Africa',locale:'Timbuktu',},
        {zone:'Africa/Tripoli',region:'Africa',locale:'Tripoli',},
        {zone:'Africa/Tunis',region:'Africa',locale:'Tunis',},
        {zone:'Africa/Windhoek',region:'Africa',locale:'Windhoek',},
        {zone:'America/Adak',region:'America',locale:'Adak',},
        {zone:'America/Anchorage',region:'America',locale:'Anchorage',},
        {zone:'America/Anguilla',region:'America',locale:'Anguilla',},
        {zone:'America/Antigua',region:'America',locale:'Antigua',},
        {zone:'America/Araguaina',region:'America',locale:'Araguaina',},
        {zone:'America/Argentina/Buenos_Aires',region:'America',locale:'Argentina/Buenos_Aires',},
        {zone:'America/Argentina/Catamarca',region:'America',locale:'Argentina/Catamarca',},
        {zone:'America/Argentina/ComodRivadavia',region:'America',locale:'Argentina/ComodRivadavia',},
        {zone:'America/Argentina/Cordoba',region:'America',locale:'Argentina/Cordoba',},
        {zone:'America/Argentina/Jujuy',region:'America',locale:'Argentina/Jujuy',},
        {zone:'America/Argentina/La_Rioja',region:'America',locale:'Argentina/La_Rioja',},
        {zone:'America/Argentina/Mendoza',region:'America',locale:'Argentina/Mendoza',},
        {zone:'America/Argentina/Rio_Gallegos',region:'America',locale:'Argentina/Rio_Gallegos',},
        {zone:'America/Argentina/Salta',region:'America',locale:'Argentina/Salta',},
        {zone:'America/Argentina/San_Juan',region:'America',locale:'Argentina/San_Juan',},
        {zone:'America/Argentina/San_Luis',region:'America',locale:'Argentina/San_Luis',},
        {zone:'America/Argentina/Tucuman',region:'America',locale:'Argentina/Tucuman',},
        {zone:'America/Argentina/Ushuaia',region:'America',locale:'Argentina/Ushuaia',},
        {zone:'America/Aruba',region:'America',locale:'Aruba',},
        {zone:'America/Asuncion',region:'America',locale:'Asuncion',},
        {zone:'America/Atikokan',region:'America',locale:'Atikokan',},
        {zone:'America/Atka',region:'America',locale:'Atka',},
        {zone:'America/Bahia',region:'America',locale:'Bahia',},
        {zone:'America/Bahia_Banderas',region:'America',locale:'Bahia_Banderas',},
        {zone:'America/Barbados',region:'America',locale:'Barbados',},
        {zone:'America/Belem',region:'America',locale:'Belem',},
        {zone:'America/Belize',region:'America',locale:'Belize',},
        {zone:'America/Blanc-Sablon',region:'America',locale:'Blanc-Sablon',},
        {zone:'America/Boa_Vista',region:'America',locale:'Boa_Vista',},
        {zone:'America/Bogota',region:'America',locale:'Bogota',},
        {zone:'America/Boise',region:'America',locale:'Boise',},
        {zone:'America/Buenos_Aires',region:'America',locale:'Buenos_Aires',},
        {zone:'America/Cambridge_Bay',region:'America',locale:'Cambridge_Bay',},
        {zone:'America/Campo_Grande',region:'America',locale:'Campo_Grande',},
        {zone:'America/Cancun',region:'America',locale:'Cancun',},
        {zone:'America/Caracas',region:'America',locale:'Caracas',},
        {zone:'America/Catamarca',region:'America',locale:'Catamarca',},
        {zone:'America/Cayenne',region:'America',locale:'Cayenne',},
        {zone:'America/Cayman',region:'America',locale:'Cayman',},
        {zone:'America/Chicago',region:'America',locale:'Chicago',},
        {zone:'America/Chihuahua',region:'America',locale:'Chihuahua',},
        {zone:'America/Ciudad_Juarez',region:'America',locale:'Ciudad_Juarez',},
        {zone:'America/Coral_Harbour',region:'America',locale:'Coral_Harbour',},
        {zone:'America/Cordoba',region:'America',locale:'Cordoba',},
        {zone:'America/Costa_Rica',region:'America',locale:'Costa_Rica',},
        {zone:'America/Creston',region:'America',locale:'Creston',},
        {zone:'America/Cuiaba',region:'America',locale:'Cuiaba',},
        {zone:'America/Curacao',region:'America',locale:'Curacao',},
        {zone:'America/Danmarkshavn',region:'America',locale:'Danmarkshavn',},
        {zone:'America/Dawson',region:'America',locale:'Dawson',},
        {zone:'America/Dawson_Creek',region:'America',locale:'Dawson_Creek',},
        {zone:'America/Denver',region:'America',locale:'Denver',},
        {zone:'America/Detroit',region:'America',locale:'Detroit',},
        {zone:'America/Dominica',region:'America',locale:'Dominica',},
        {zone:'America/Edmonton',region:'America',locale:'Edmonton',},
        {zone:'America/Eirunepe',region:'America',locale:'Eirunepe',},
        {zone:'America/El_Salvador',region:'America',locale:'El_Salvador',},
        {zone:'America/Ensenada',region:'America',locale:'Ensenada',},
        {zone:'America/Fort_Nelson',region:'America',locale:'Fort_Nelson',},
        {zone:'America/Fort_Wayne',region:'America',locale:'Fort_Wayne',},
        {zone:'America/Fortaleza',region:'America',locale:'Fortaleza',},
        {zone:'America/Glace_Bay',region:'America',locale:'Glace_Bay',},
        {zone:'America/Godthab',region:'America',locale:'Godthab',},
        {zone:'America/Goose_Bay',region:'America',locale:'Goose_Bay',},
        {zone:'America/Grand_Turk',region:'America',locale:'Grand_Turk',},
        {zone:'America/Grenada',region:'America',locale:'Grenada',},
        {zone:'America/Guadeloupe',region:'America',locale:'Guadeloupe',},
        {zone:'America/Guatemala',region:'America',locale:'Guatemala',},
        {zone:'America/Guayaquil',region:'America',locale:'Guayaquil',},
        {zone:'America/Guyana',region:'America',locale:'Guyana',},
        {zone:'America/Halifax',region:'America',locale:'Halifax',},
        {zone:'America/Havana',region:'America',locale:'Havana',},
        {zone:'America/Hermosillo',region:'America',locale:'Hermosillo',},
        {zone:'America/Indiana/Indianapolis',region:'America',locale:'Indiana/Indianapolis',},
        {zone:'America/Indiana/Knox',region:'America',locale:'Indiana/Knox',},
        {zone:'America/Indiana/Marengo',region:'America',locale:'Indiana/Marengo',},
        {zone:'America/Indiana/Petersburg',region:'America',locale:'Indiana/Petersburg',},
        {zone:'America/Indiana/Tell_City',region:'America',locale:'Indiana/Tell_City',},
        {zone:'America/Indiana/Vevay',region:'America',locale:'Indiana/Vevay',},
        {zone:'America/Indiana/Vincennes',region:'America',locale:'Indiana/Vincennes',},
        {zone:'America/Indiana/Winamac',region:'America',locale:'Indiana/Winamac',},
        {zone:'America/Indianapolis',region:'America',locale:'Indianapolis',},
        {zone:'America/Inuvik',region:'America',locale:'Inuvik',},
        {zone:'America/Iqaluit',region:'America',locale:'Iqaluit',},
        {zone:'America/Jamaica',region:'America',locale:'Jamaica',},
        {zone:'America/Jujuy',region:'America',locale:'Jujuy',},
        {zone:'America/Juneau',region:'America',locale:'Juneau',},
        {zone:'America/Kentucky/Louisville',region:'America',locale:'Kentucky/Louisville',},
        {zone:'America/Kentucky/Monticello',region:'America',locale:'Kentucky/Monticello',},
        {zone:'America/Knox_IN',region:'America',locale:'Knox_IN',},
        {zone:'America/Kralendijk',region:'America',locale:'Kralendijk',},
        {zone:'America/La_Paz',region:'America',locale:'La_Paz',},
        {zone:'America/Lima',region:'America',locale:'Lima',},
        {zone:'America/Los_Angeles',region:'America',locale:'Los_Angeles',},
        {zone:'America/Louisville',region:'America',locale:'Louisville',},
        {zone:'America/Lower_Princes',region:'America',locale:'Lower_Princes',},
        {zone:'America/Maceio',region:'America',locale:'Maceio',},
        {zone:'America/Managua',region:'America',locale:'Managua',},
        {zone:'America/Manaus',region:'America',locale:'Manaus',},
        {zone:'America/Marigot',region:'America',locale:'Marigot',},
        {zone:'America/Martinique',region:'America',locale:'Martinique',},
        {zone:'America/Matamoros',region:'America',locale:'Matamoros',},
        {zone:'America/Mazatlan',region:'America',locale:'Mazatlan',},
        {zone:'America/Mendoza',region:'America',locale:'Mendoza',},
        {zone:'America/Menominee',region:'America',locale:'Menominee',},
        {zone:'America/Merida',region:'America',locale:'Merida',},
        {zone:'America/Metlakatla',region:'America',locale:'Metlakatla',},
        {zone:'America/Mexico_City',region:'America',locale:'Mexico_City',},
        {zone:'America/Miquelon',region:'America',locale:'Miquelon',},
        {zone:'America/Moncton',region:'America',locale:'Moncton',},
        {zone:'America/Monterrey',region:'America',locale:'Monterrey',},
        {zone:'America/Montevideo',region:'America',locale:'Montevideo',},
        {zone:'America/Montreal',region:'America',locale:'Montreal',},
        {zone:'America/Montserrat',region:'America',locale:'Montserrat',},
        {zone:'America/Nassau',region:'America',locale:'Nassau',},
        {zone:'America/New_York',region:'America',locale:'New_York',},
        {zone:'America/Nipigon',region:'America',locale:'Nipigon',},
        {zone:'America/Nome',region:'America',locale:'Nome',},
        {zone:'America/Noronha',region:'America',locale:'Noronha',},
        {zone:'America/North_Dakota/Beulah',region:'America',locale:'North_Dakota/Beulah',},
        {zone:'America/North_Dakota/Center',region:'America',locale:'North_Dakota/Center',},
        {zone:'America/North_Dakota/New_Salem',region:'America',locale:'North_Dakota/New_Salem',},
        {zone:'America/Nuuk',region:'America',locale:'Nuuk',},
        {zone:'America/Ojinaga',region:'America',locale:'Ojinaga',},
        {zone:'America/Panama',region:'America',locale:'Panama',},
        {zone:'America/Pangnirtung',region:'America',locale:'Pangnirtung',},
        {zone:'America/Paramaribo',region:'America',locale:'Paramaribo',},
        {zone:'America/Phoenix',region:'America',locale:'Phoenix',},
        {zone:'America/Port-au-Prince',region:'America',locale:'Port-au-Prince',},
        {zone:'America/Port_of_Spain',region:'America',locale:'Port_of_Spain',},
        {zone:'America/Porto_Acre',region:'America',locale:'Porto_Acre',},
        {zone:'America/Porto_Velho',region:'America',locale:'Porto_Velho',},
        {zone:'America/Puerto_Rico',region:'America',locale:'Puerto_Rico',},
        {zone:'America/Punta_Arenas',region:'America',locale:'Punta_Arenas',},
        {zone:'America/Rainy_River',region:'America',locale:'Rainy_River',},
        {zone:'America/Rankin_Inlet',region:'America',locale:'Rankin_Inlet',},
        {zone:'America/Recife',region:'America',locale:'Recife',},
        {zone:'America/Regina',region:'America',locale:'Regina',},
        {zone:'America/Resolute',region:'America',locale:'Resolute',},
        {zone:'America/Rio_Branco',region:'America',locale:'Rio_Branco',},
        {zone:'America/Rosario',region:'America',locale:'Rosario',},
        {zone:'America/Santa_Isabel',region:'America',locale:'Santa_Isabel',},
        {zone:'America/Santarem',region:'America',locale:'Santarem',},
        {zone:'America/Santiago',region:'America',locale:'Santiago',},
        {zone:'America/Santo_Domingo',region:'America',locale:'Santo_Domingo',},
        {zone:'America/Sao_Paulo',region:'America',locale:'Sao_Paulo',},
        {zone:'America/Scoresbysund',region:'America',locale:'Scoresbysund',},
        {zone:'America/Shiprock',region:'America',locale:'Shiprock',},
        {zone:'America/Sitka',region:'America',locale:'Sitka',},
        {zone:'America/St_Barthelemy',region:'America',locale:'St_Barthelemy',},
        {zone:'America/St_Johns',region:'America',locale:'St_Johns',},
        {zone:'America/St_Kitts',region:'America',locale:'St_Kitts',},
        {zone:'America/St_Lucia',region:'America',locale:'St_Lucia',},
        {zone:'America/St_Thomas',region:'America',locale:'St_Thomas',},
        {zone:'America/St_Vincent',region:'America',locale:'St_Vincent',},
        {zone:'America/Swift_Current',region:'America',locale:'Swift_Current',},
        {zone:'America/Tegucigalpa',region:'America',locale:'Tegucigalpa',},
        {zone:'America/Thule',region:'America',locale:'Thule',},
        {zone:'America/Thunder_Bay',region:'America',locale:'Thunder_Bay',},
        {zone:'America/Tijuana',region:'America',locale:'Tijuana',},
        {zone:'America/Toronto',region:'America',locale:'Toronto',},
        {zone:'America/Tortola',region:'America',locale:'Tortola',},
        {zone:'America/Vancouver',region:'America',locale:'Vancouver',},
        {zone:'America/Virgin',region:'America',locale:'Virgin',},
        {zone:'America/Whitehorse',region:'America',locale:'Whitehorse',},
        {zone:'America/Winnipeg',region:'America',locale:'Winnipeg',},
        {zone:'America/Yakutat',region:'America',locale:'Yakutat',},
        {zone:'America/Yellowknife',region:'America',locale:'Yellowknife',},
        {zone:'Antarctica/Casey',region:'Antarctica',locale:'Casey',},
        {zone:'Antarctica/Davis',region:'Antarctica',locale:'Davis',},
        {zone:'Antarctica/DumontDUrville',region:'Antarctica',locale:'DumontDUrville',},
        {zone:'Antarctica/Macquarie',region:'Antarctica',locale:'Macquarie',},
        {zone:'Antarctica/Mawson',region:'Antarctica',locale:'Mawson',},
        {zone:'Antarctica/McMurdo',region:'Antarctica',locale:'McMurdo',},
        {zone:'Antarctica/Palmer',region:'Antarctica',locale:'Palmer',},
        {zone:'Antarctica/Rothera',region:'Antarctica',locale:'Rothera',},
        {zone:'Antarctica/South_Pole',region:'Antarctica',locale:'South_Pole',},
        {zone:'Antarctica/Syowa',region:'Antarctica',locale:'Syowa',},
        {zone:'Antarctica/Troll',region:'Antarctica',locale:'Troll',},
        {zone:'Antarctica/Vostok',region:'Antarctica',locale:'Vostok',},
        {zone:'Arctic/Longyearbyen',region:'Arctic',locale:'Longyearbyen',},
        {zone:'Asia/Aden',region:'Asia',locale:'Aden',},
        {zone:'Asia/Almaty',region:'Asia',locale:'Almaty',},
        {zone:'Asia/Amman',region:'Asia',locale:'Amman',},
        {zone:'Asia/Anadyr',region:'Asia',locale:'Anadyr',},
        {zone:'Asia/Aqtau',region:'Asia',locale:'Aqtau',},
        {zone:'Asia/Aqtobe',region:'Asia',locale:'Aqtobe',},
        {zone:'Asia/Ashgabat',region:'Asia',locale:'Ashgabat',},
        {zone:'Asia/Ashkhabad',region:'Asia',locale:'Ashkhabad',},
        {zone:'Asia/Atyrau',region:'Asia',locale:'Atyrau',},
        {zone:'Asia/Baghdad',region:'Asia',locale:'Baghdad',},
        {zone:'Asia/Bahrain',region:'Asia',locale:'Bahrain',},
        {zone:'Asia/Baku',region:'Asia',locale:'Baku',},
        {zone:'Asia/Bangkok',region:'Asia',locale:'Bangkok',},
        {zone:'Asia/Barnaul',region:'Asia',locale:'Barnaul',},
        {zone:'Asia/Beirut',region:'Asia',locale:'Beirut',},
        {zone:'Asia/Bishkek',region:'Asia',locale:'Bishkek',},
        {zone:'Asia/Brunei',region:'Asia',locale:'Brunei',},
        {zone:'Asia/Calcutta',region:'Asia',locale:'Calcutta',},
        {zone:'Asia/Chita',region:'Asia',locale:'Chita',},
        {zone:'Asia/Choibalsan',region:'Asia',locale:'Choibalsan',},
        {zone:'Asia/Chongqing',region:'Asia',locale:'Chongqing',},
        {zone:'Asia/Chungking',region:'Asia',locale:'Chungking',},
        {zone:'Asia/Colombo',region:'Asia',locale:'Colombo',},
        {zone:'Asia/Dacca',region:'Asia',locale:'Dacca',},
        {zone:'Asia/Damascus',region:'Asia',locale:'Damascus',},
        {zone:'Asia/Dhaka',region:'Asia',locale:'Dhaka',},
        {zone:'Asia/Dili',region:'Asia',locale:'Dili',},
        {zone:'Asia/Dubai',region:'Asia',locale:'Dubai',},
        {zone:'Asia/Dushanbe',region:'Asia',locale:'Dushanbe',},
        {zone:'Asia/Famagusta',region:'Asia',locale:'Famagusta',},
        {zone:'Asia/Gaza',region:'Asia',locale:'Gaza',},
        {zone:'Asia/Harbin',region:'Asia',locale:'Harbin',},
        {zone:'Asia/Hebron',region:'Asia',locale:'Hebron',},
        {zone:'Asia/Ho_Chi_Minh',region:'Asia',locale:'Ho_Chi_Minh',},
        {zone:'Asia/Hong_Kong',region:'Asia',locale:'Hong_Kong',},
        {zone:'Asia/Hovd',region:'Asia',locale:'Hovd',},
        {zone:'Asia/Irkutsk',region:'Asia',locale:'Irkutsk',},
        {zone:'Asia/Istanbul',region:'Asia',locale:'Istanbul',},
        {zone:'Asia/Jakarta',region:'Asia',locale:'Jakarta',},
        {zone:'Asia/Jayapura',region:'Asia',locale:'Jayapura',},
        {zone:'Asia/Jerusalem',region:'Asia',locale:'Jerusalem',},
        {zone:'Asia/Kabul',region:'Asia',locale:'Kabul',},
        {zone:'Asia/Kamchatka',region:'Asia',locale:'Kamchatka',},
        {zone:'Asia/Karachi',region:'Asia',locale:'Karachi',},
        {zone:'Asia/Kashgar',region:'Asia',locale:'Kashgar',},
        {zone:'Asia/Kathmandu',region:'Asia',locale:'Kathmandu',},
        {zone:'Asia/Katmandu',region:'Asia',locale:'Katmandu',},
        {zone:'Asia/Khandyga',region:'Asia',locale:'Khandyga',},
        {zone:'Asia/Kolkata',region:'Asia',locale:'Kolkata',},
        {zone:'Asia/Krasnoyarsk',region:'Asia',locale:'Krasnoyarsk',},
        {zone:'Asia/Kuala_Lumpur',region:'Asia',locale:'Kuala_Lumpur',},
        {zone:'Asia/Kuching',region:'Asia',locale:'Kuching',},
        {zone:'Asia/Kuwait',region:'Asia',locale:'Kuwait',},
        {zone:'Asia/Macao',region:'Asia',locale:'Macao',},
        {zone:'Asia/Macau',region:'Asia',locale:'Macau',},
        {zone:'Asia/Magadan',region:'Asia',locale:'Magadan',},
        {zone:'Asia/Makassar',region:'Asia',locale:'Makassar',},
        {zone:'Asia/Manila',region:'Asia',locale:'Manila',},
        {zone:'Asia/Muscat',region:'Asia',locale:'Muscat',},
        {zone:'Asia/Nicosia',region:'Asia',locale:'Nicosia',},
        {zone:'Asia/Novokuznetsk',region:'Asia',locale:'Novokuznetsk',},
        {zone:'Asia/Novosibirsk',region:'Asia',locale:'Novosibirsk',},
        {zone:'Asia/Omsk',region:'Asia',locale:'Omsk',},
        {zone:'Asia/Oral',region:'Asia',locale:'Oral',},
        {zone:'Asia/Phnom_Penh',region:'Asia',locale:'Phnom_Penh',},
        {zone:'Asia/Pontianak',region:'Asia',locale:'Pontianak',},
        {zone:'Asia/Pyongyang',region:'Asia',locale:'Pyongyang',},
        {zone:'Asia/Qatar',region:'Asia',locale:'Qatar',},
        {zone:'Asia/Qostanay',region:'Asia',locale:'Qostanay',},
        {zone:'Asia/Qyzylorda',region:'Asia',locale:'Qyzylorda',},
        {zone:'Asia/Rangoon',region:'Asia',locale:'Rangoon',},
        {zone:'Asia/Riyadh',region:'Asia',locale:'Riyadh',},
        {zone:'Asia/Saigon',region:'Asia',locale:'Saigon',},
        {zone:'Asia/Sakhalin',region:'Asia',locale:'Sakhalin',},
        {zone:'Asia/Samarkand',region:'Asia',locale:'Samarkand',},
        {zone:'Asia/Seoul',region:'Asia',locale:'Seoul',},
        {zone:'Asia/Shanghai',region:'Asia',locale:'Shanghai',},
        {zone:'Asia/Singapore',region:'Asia',locale:'Singapore',},
        {zone:'Asia/Srednekolymsk',region:'Asia',locale:'Srednekolymsk',},
        {zone:'Asia/Taipei',region:'Asia',locale:'Taipei',},
        {zone:'Asia/Tashkent',region:'Asia',locale:'Tashkent',},
        {zone:'Asia/Tbilisi',region:'Asia',locale:'Tbilisi',},
        {zone:'Asia/Tehran',region:'Asia',locale:'Tehran',},
        {zone:'Asia/Tel_Aviv',region:'Asia',locale:'Tel_Aviv',},
        {zone:'Asia/Thimbu',region:'Asia',locale:'Thimbu',},
        {zone:'Asia/Thimphu',region:'Asia',locale:'Thimphu',},
        {zone:'Asia/Tokyo',region:'Asia',locale:'Tokyo',},
        {zone:'Asia/Tomsk',region:'Asia',locale:'Tomsk',},
        {zone:'Asia/Ujung_Pandang',region:'Asia',locale:'Ujung_Pandang',},
        {zone:'Asia/Ulaanbaatar',region:'Asia',locale:'Ulaanbaatar',},
        {zone:'Asia/Ulan_Bator',region:'Asia',locale:'Ulan_Bator',},
        {zone:'Asia/Urumqi',region:'Asia',locale:'Urumqi',},
        {zone:'Asia/Ust-Nera',region:'Asia',locale:'Ust-Nera',},
        {zone:'Asia/Vientiane',region:'Asia',locale:'Vientiane',},
        {zone:'Asia/Vladivostok',region:'Asia',locale:'Vladivostok',},
        {zone:'Asia/Yakutsk',region:'Asia',locale:'Yakutsk',},
        {zone:'Asia/Yangon',region:'Asia',locale:'Yangon',},
        {zone:'Asia/Yekaterinburg',region:'Asia',locale:'Yekaterinburg',},
        {zone:'Asia/Yerevan',region:'Asia',locale:'Yerevan',},
        {zone:'Atlantic/Azores',region:'Atlantic',locale:'Azores',},
        {zone:'Atlantic/Bermuda',region:'Atlantic',locale:'Bermuda',},
        {zone:'Atlantic/Canary',region:'Atlantic',locale:'Canary',},
        {zone:'Atlantic/Cape_Verde',region:'Atlantic',locale:'Cape_Verde',},
        {zone:'Atlantic/Faeroe',region:'Atlantic',locale:'Faeroe',},
        {zone:'Atlantic/Faroe',region:'Atlantic',locale:'Faroe',},
        {zone:'Atlantic/Jan_Mayen',region:'Atlantic',locale:'Jan_Mayen',},
        {zone:'Atlantic/Madeira',region:'Atlantic',locale:'Madeira',},
        {zone:'Atlantic/Reykjavik',region:'Atlantic',locale:'Reykjavik',},
        {zone:'Atlantic/South_Georgia',region:'Atlantic',locale:'South_Georgia',},
        {zone:'Atlantic/St_Helena',region:'Atlantic',locale:'St_Helena',},
        {zone:'Atlantic/Stanley',region:'Atlantic',locale:'Stanley',},
        {zone:'Australia/ACT',region:'Australia',locale:'ACT',},
        {zone:'Australia/Adelaide',region:'Australia',locale:'Adelaide',},
        {zone:'Australia/Brisbane',region:'Australia',locale:'Brisbane',},
        {zone:'Australia/Broken_Hill',region:'Australia',locale:'Broken_Hill',},
        {zone:'Australia/Canberra',region:'Australia',locale:'Canberra',},
        {zone:'Australia/Currie',region:'Australia',locale:'Currie',},
        {zone:'Australia/Darwin',region:'Australia',locale:'Darwin',},
        {zone:'Australia/Eucla',region:'Australia',locale:'Eucla',},
        {zone:'Australia/Hobart',region:'Australia',locale:'Hobart',},
        {zone:'Australia/LHI',region:'Australia',locale:'LHI',},
        {zone:'Australia/Lindeman',region:'Australia',locale:'Lindeman',},
        {zone:'Australia/Lord_Howe',region:'Australia',locale:'Lord_Howe',},
        {zone:'Australia/Melbourne',region:'Australia',locale:'Melbourne',},
        {zone:'Australia/NSW',region:'Australia',locale:'NSW',},
        {zone:'Australia/North',region:'Australia',locale:'North',},
        {zone:'Australia/Perth',region:'Australia',locale:'Perth',},
        {zone:'Australia/Queensland',region:'Australia',locale:'Queensland',},
        {zone:'Australia/South',region:'Australia',locale:'South',},
        {zone:'Australia/Sydney',region:'Australia',locale:'Sydney',},
        {zone:'Australia/Tasmania',region:'Australia',locale:'Tasmania',},
        {zone:'Australia/Victoria',region:'Australia',locale:'Victoria',},
        {zone:'Australia/West',region:'Australia',locale:'West',},
        {zone:'Australia/Yancowinna',region:'Australia',locale:'Yancowinna',},
        {zone:'Brazil/Acre',region:'Brazil',locale:'Acre',},
        {zone:'Brazil/DeNoronha',region:'Brazil',locale:'DeNoronha',},
        {zone:'Brazil/East',region:'Brazil',locale:'East',},
        {zone:'Brazil/West',region:'Brazil',locale:'West',},
        {zone:'Canada/Atlantic',region:'Canada',locale:'Atlantic',},
        {zone:'Canada/Central',region:'Canada',locale:'Central',},
        {zone:'Canada/Eastern',region:'Canada',locale:'Eastern',},
        {zone:'Canada/Mountain',region:'Canada',locale:'Mountain',},
        {zone:'Canada/Newfoundland',region:'Canada',locale:'Newfoundland',},
        {zone:'Canada/Pacific',region:'Canada',locale:'Pacific',},
        {zone:'Canada/Saskatchewan',region:'Canada',locale:'Saskatchewan',},
        {zone:'Canada/Yukon',region:'Canada',locale:'Yukon',},
        {zone:'Chile/Continental',region:'Chile',locale:'Continental',},
        {zone:'Chile/EasterIsland',region:'Chile',locale:'EasterIsland',},
        {zone:'Europe/Amsterdam',region:'Europe',locale:'Amsterdam',},
        {zone:'Europe/Andorra',region:'Europe',locale:'Andorra',},
        {zone:'Europe/Astrakhan',region:'Europe',locale:'Astrakhan',},
        {zone:'Europe/Athens',region:'Europe',locale:'Athens',},
        {zone:'Europe/Belfast',region:'Europe',locale:'Belfast',},
        {zone:'Europe/Belgrade',region:'Europe',locale:'Belgrade',},
        {zone:'Europe/Berlin',region:'Europe',locale:'Berlin',},
        {zone:'Europe/Bratislava',region:'Europe',locale:'Bratislava',},
        {zone:'Europe/Brussels',region:'Europe',locale:'Brussels',},
        {zone:'Europe/Bucharest',region:'Europe',locale:'Bucharest',},
        {zone:'Europe/Budapest',region:'Europe',locale:'Budapest',},
        {zone:'Europe/Busingen',region:'Europe',locale:'Busingen',},
        {zone:'Europe/Chisinau',region:'Europe',locale:'Chisinau',},
        {zone:'Europe/Copenhagen',region:'Europe',locale:'Copenhagen',},
        {zone:'Europe/Dublin',region:'Europe',locale:'Dublin',},
        {zone:'Europe/Gibraltar',region:'Europe',locale:'Gibraltar',},
        {zone:'Europe/Guernsey',region:'Europe',locale:'Guernsey',},
        {zone:'Europe/Helsinki',region:'Europe',locale:'Helsinki',},
        {zone:'Europe/Isle_of_Man',region:'Europe',locale:'Isle_of_Man',},
        {zone:'Europe/Istanbul',region:'Europe',locale:'Istanbul',},
        {zone:'Europe/Jersey',region:'Europe',locale:'Jersey',},
        {zone:'Europe/Kaliningrad',region:'Europe',locale:'Kaliningrad',},
        {zone:'Europe/Kiev',region:'Europe',locale:'Kiev',},
        {zone:'Europe/Kirov',region:'Europe',locale:'Kirov',},
        {zone:'Europe/Kyiv',region:'Europe',locale:'Kyiv',},
        {zone:'Europe/Lisbon',region:'Europe',locale:'Lisbon',},
        {zone:'Europe/Ljubljana',region:'Europe',locale:'Ljubljana',},
        {zone:'Europe/London',region:'Europe',locale:'London',},
        {zone:'Europe/Luxembourg',region:'Europe',locale:'Luxembourg',},
        {zone:'Europe/Madrid',region:'Europe',locale:'Madrid',},
        {zone:'Europe/Malta',region:'Europe',locale:'Malta',},
        {zone:'Europe/Mariehamn',region:'Europe',locale:'Mariehamn',},
        {zone:'Europe/Minsk',region:'Europe',locale:'Minsk',},
        {zone:'Europe/Monaco',region:'Europe',locale:'Monaco',},
        {zone:'Europe/Moscow',region:'Europe',locale:'Moscow',},
        {zone:'Europe/Nicosia',region:'Europe',locale:'Nicosia',},
        {zone:'Europe/Oslo',region:'Europe',locale:'Oslo',},
        {zone:'Europe/Paris',region:'Europe',locale:'Paris',},
        {zone:'Europe/Podgorica',region:'Europe',locale:'Podgorica',},
        {zone:'Europe/Prague',region:'Europe',locale:'Prague',},
        {zone:'Europe/Riga',region:'Europe',locale:'Riga',},
        {zone:'Europe/Rome',region:'Europe',locale:'Rome',},
        {zone:'Europe/Samara',region:'Europe',locale:'Samara',},
        {zone:'Europe/San_Marino',region:'Europe',locale:'San_Marino',},
        {zone:'Europe/Sarajevo',region:'Europe',locale:'Sarajevo',},
        {zone:'Europe/Saratov',region:'Europe',locale:'Saratov',},
        {zone:'Europe/Simferopol',region:'Europe',locale:'Simferopol',},
        {zone:'Europe/Skopje',region:'Europe',locale:'Skopje',},
        {zone:'Europe/Sofia',region:'Europe',locale:'Sofia',},
        {zone:'Europe/Stockholm',region:'Europe',locale:'Stockholm',},
        {zone:'Europe/Tallinn',region:'Europe',locale:'Tallinn',},
        {zone:'Europe/Tirane',region:'Europe',locale:'Tirane',},
        {zone:'Europe/Tiraspol',region:'Europe',locale:'Tiraspol',},
        {zone:'Europe/Ulyanovsk',region:'Europe',locale:'Ulyanovsk',},
        {zone:'Europe/Uzhgorod',region:'Europe',locale:'Uzhgorod',},
        {zone:'Europe/Vaduz',region:'Europe',locale:'Vaduz',},
        {zone:'Europe/Vatican',region:'Europe',locale:'Vatican',},
        {zone:'Europe/Vienna',region:'Europe',locale:'Vienna',},
        {zone:'Europe/Vilnius',region:'Europe',locale:'Vilnius',},
        {zone:'Europe/Volgograd',region:'Europe',locale:'Volgograd',},
        {zone:'Europe/Warsaw',region:'Europe',locale:'Warsaw',},
        {zone:'Europe/Zagreb',region:'Europe',locale:'Zagreb',},
        {zone:'Europe/Zaporozhye',region:'Europe',locale:'Zaporozhye',},
        {zone:'Europe/Zurich',region:'Europe',locale:'Zurich',},
        {zone:'Indian/Antananarivo',region:'Indian',locale:'Antananarivo',},
        {zone:'Indian/Chagos',region:'Indian',locale:'Chagos',},
        {zone:'Indian/Christmas',region:'Indian',locale:'Christmas',},
        {zone:'Indian/Cocos',region:'Indian',locale:'Cocos',},
        {zone:'Indian/Comoro',region:'Indian',locale:'Comoro',},
        {zone:'Indian/Kerguelen',region:'Indian',locale:'Kerguelen',},
        {zone:'Indian/Mahe',region:'Indian',locale:'Mahe',},
        {zone:'Indian/Maldives',region:'Indian',locale:'Maldives',},
        {zone:'Indian/Mauritius',region:'Indian',locale:'Mauritius',},
        {zone:'Indian/Mayotte',region:'Indian',locale:'Mayotte',},
        {zone:'Indian/Reunion',region:'Indian',locale:'Reunion',},
        {zone:'Mexico/BajaNorte',region:'Mexico',locale:'BajaNorte',},
        {zone:'Mexico/BajaSur',region:'Mexico',locale:'BajaSur',},
        {zone:'Mexico/General',region:'Mexico',locale:'General',},
        {zone:'Pacific/Apia',region:'Pacific',locale:'Apia',},
        {zone:'Pacific/Auckland',region:'Pacific',locale:'Auckland',},
        {zone:'Pacific/Bougainville',region:'Pacific',locale:'Bougainville',},
        {zone:'Pacific/Chatham',region:'Pacific',locale:'Chatham',},
        {zone:'Pacific/Chuuk',region:'Pacific',locale:'Chuuk',},
        {zone:'Pacific/Easter',region:'Pacific',locale:'Easter',},
        {zone:'Pacific/Efate',region:'Pacific',locale:'Efate',},
        {zone:'Pacific/Enderbury',region:'Pacific',locale:'Enderbury',},
        {zone:'Pacific/Fakaofo',region:'Pacific',locale:'Fakaofo',},
        {zone:'Pacific/Fiji',region:'Pacific',locale:'Fiji',},
        {zone:'Pacific/Funafuti',region:'Pacific',locale:'Funafuti',},
        {zone:'Pacific/Galapagos',region:'Pacific',locale:'Galapagos',},
        {zone:'Pacific/Gambier',region:'Pacific',locale:'Gambier',},
        {zone:'Pacific/Guadalcanal',region:'Pacific',locale:'Guadalcanal',},
        {zone:'Pacific/Guam',region:'Pacific',locale:'Guam',},
        {zone:'Pacific/Honolulu',region:'Pacific',locale:'Honolulu',},
        {zone:'Pacific/Johnston',region:'Pacific',locale:'Johnston',},
        {zone:'Pacific/Kanton',region:'Pacific',locale:'Kanton',},
        {zone:'Pacific/Kiritimati',region:'Pacific',locale:'Kiritimati',},
        {zone:'Pacific/Kosrae',region:'Pacific',locale:'Kosrae',},
        {zone:'Pacific/Kwajalein',region:'Pacific',locale:'Kwajalein',},
        {zone:'Pacific/Majuro',region:'Pacific',locale:'Majuro',},
        {zone:'Pacific/Marquesas',region:'Pacific',locale:'Marquesas',},
        {zone:'Pacific/Midway',region:'Pacific',locale:'Midway',},
        {zone:'Pacific/Nauru',region:'Pacific',locale:'Nauru',},
        {zone:'Pacific/Niue',region:'Pacific',locale:'Niue',},
        {zone:'Pacific/Norfolk',region:'Pacific',locale:'Norfolk',},
        {zone:'Pacific/Noumea',region:'Pacific',locale:'Noumea',},
        {zone:'Pacific/Pago_Pago',region:'Pacific',locale:'Pago_Pago',},
        {zone:'Pacific/Palau',region:'Pacific',locale:'Palau',},
        {zone:'Pacific/Pitcairn',region:'Pacific',locale:'Pitcairn',},
        {zone:'Pacific/Pohnpei',region:'Pacific',locale:'Pohnpei',},
        {zone:'Pacific/Ponape',region:'Pacific',locale:'Ponape',},
        {zone:'Pacific/Port_Moresby',region:'Pacific',locale:'Port_Moresby',},
        {zone:'Pacific/Rarotonga',region:'Pacific',locale:'Rarotonga',},
        {zone:'Pacific/Saipan',region:'Pacific',locale:'Saipan',},
        {zone:'Pacific/Samoa',region:'Pacific',locale:'Samoa',},
        {zone:'Pacific/Tahiti',region:'Pacific',locale:'Tahiti',},
        {zone:'Pacific/Tarawa',region:'Pacific',locale:'Tarawa',},
        {zone:'Pacific/Tongatapu',region:'Pacific',locale:'Tongatapu',},
        {zone:'Pacific/Truk',region:'Pacific',locale:'Truk',},
        {zone:'Pacific/Wake',region:'Pacific',locale:'Wake',},
        {zone:'Pacific/Wallis',region:'Pacific',locale:'Wallis',},
        {zone:'Pacific/Yap',region:'Pacific',locale:'Yap',},
        {zone:'US/Alaska',region:'US',locale:'Alaska',},
        {zone:'US/Aleutian',region:'US',locale:'Aleutian',},
        {zone:'US/Arizona',region:'US',locale:'Arizona',},
        {zone:'US/Central',region:'US',locale:'Central',},
        {zone:'US/East-Indiana',region:'US',locale:'East-Indiana',},
        {zone:'US/Eastern',region:'US',locale:'Eastern',},
        {zone:'US/Hawaii',region:'US',locale:'Hawaii',},
        {zone:'US/Indiana-Starke',region:'US',locale:'Indiana-Starke',},
        {zone:'US/Michigan',region:'US',locale:'Michigan',},
        {zone:'US/Mountain',region:'US',locale:'Mountain',},
        {zone:'US/Pacific',region:'US',locale:'Pacific',},
        {zone:'US/Samoa',region:'US',locale:'Samoa',},

    ]

    const regions = ['Africa', 'America', 'Antarctica', 'Arctic', 'Asia', 'Atlantic', 'Australia', 'Brazil', 'Canada', 'Chile', 'Europe', 'Indian', 'Mexico', 'Pacific', 'US']
    const selectedRegion = ref(null)


    const filteredTimezones = computed(() => {
        return supportedTimezones.filter((tz) => tz.region === selectedRegion.value)
    })

    watch(selectedTimezone, (newVal) => {
        if (newVal) {
            const region = newVal.split('/')[0]
            selectedRegion.value = region
        }
    })

    watch(selectedRegion, (newVal, oldVal) => {
        // conditional avoids first change ofr selectTimezone from api call
        if (oldVal != null) {

            const filtered = supportedTimezones.filter((tz) => tz.region === selectedRegion.value)
            if (filtered.length > 0) {
                selectedTimezone.value = filtered[0].zone
            } else {
                console.log("No timezones found for this region");
            }
        }
    })




    // IN REVIEW




    // const lookupCountryCode = (timezone) => {
    //     const country = ct.getCountryForTimezone(timezone)

    //     return country.id // id is countryCode
    // }

    // Function to get formatted timezone details - NOT NEEDED?
    function getTimezoneDetails() {
        const timezones = moment.tz.names()
        return timezones
    }



    const get_oset = (zone) => {
        // Get current time in the specified zone with Luxon
        const nowInZone = DateTime.now().setZone(zone);

        // Format the offset to something like UTC+1 or UTC-5
        const offset = nowInZone.offset / 60;  // Offset in hours

        // Determine if the zone is in DST or not
        const isDST = nowInZone.isInDST;
        const offsetString = offset >= 0 ? `UTC+${offset}` : `UTC${offset}`;

        // Return offset with an indication if it's currently in DST
        return isDST ? `${offsetString} (DST)` : offsetString;
    }

    return {
        selectedTimezone,
        getLocation,
        get_oset,
        getTimezoneDetails,
        supportedTimezones,
        regions,
        filteredTimezones,
        selectedRegion,
    }

})