<script setup>

import { useRouter, useRoute } from 'vue-router'

import { useLanguageStore } from '../stores/language.js'
const language = useLanguageStore()

const router = useRouter()
const route = useRoute()

const changeLang = (event) => {
    language.changeUiLang(event.target.value)

    const newPath = route.path.replace(/^\/[a-z]{2}\//, `/${event.target.value}/`)
    router.push(newPath)
}

</script>

<template>
    <div class="flex items-center justify-center">
        <select id="uiLang-select" aria-label="select site language" v-model="language.uiLang"
            class="h-6 w-14 pl-2  text-sm bg-white font-medium border border-gray-500 rounded-md focus:outline-none"
            @change="(event) => changeLang(event)">
            <option v-for="supportedLang in language.supportedLanguages" :key="supportedLang.code"
                :value="supportedLang.code">
                {{ supportedLang.code }}
            </option>
        </select>
    </div>
</template>
