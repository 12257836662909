<template>

    <div class="w-full mt-8 text-sky-800 flex flex-col justify-start items-center max-w-3xl  px-4 md:p-0 space-y-12">
        <div class="w-full  flex flex-col mt-6">
            <div class="text-4xl font-semibold">
                <p>
                    FAQs
                </p>
            </div>
            <div class="w-full  flex flex-col space-y-4  mt-4">
                <div class=" space-y-2  w-full  ">

                    <div id="subheading" class="text-lg font-semibold">
                        What is in the chart download pack?
                    </div>
                    <ul class="list-disc list-inside ml-4">

                        <li>The classic tournament chart.
                        </li>

                         <li>A chronological fixture list.</li>

                        <!-- <li>Team and tournament facts.
                        </li>
                        <li>Games and quizzes.
                        </li> -->
                    </ul>

                </div>

                <div class=" space-y-2  w-full  ">
                    <div id="subheading" class="text-lg font-semibold">
                        How long will my chart pack be available for?
                    </div>
                    <p class="">

                        Charts will be available for one month after the end of the tournament.
                    </p>
                </div>





            </div>

        </div>


    </div>



</template>

<script setup>

import { useRouter } from 'vue-router'
const router = useRouter()

import { markRaw } from 'vue'

import { ArrowsPointingOutIcon, ArrowTopRightOnSquareIcon, ClockIcon, PrinterIcon, CreditCardIcon, NoSymbolIcon, LanguageIcon, EyeIcon, QrCodeIcon, ChartBarIcon } from '@heroicons/vue/24/outline'
import { IconBulb, IconBallFootball, IconCode } from '@tabler/icons-vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import EmailSubscriber from '@shared/components/EmailSubscriber.vue'
import Button from '@shared/components/Button.vue'
import Feedback from '../components/Feedback.vue'


const testFunc = async () => {
    console.log('parent button action started')
    return new Promise((resolve) => {
        setTimeout(() => {
            console.log('parent action completed')
            resolve()  // Resolve the promise after 2 seconds
        }, 2000)
    })
}

const pushDemo = async () => {
    router.push({ name: 'euro2025' })
}

const showFeedback = () => {
    ui.openRightTray(markRaw(Feedback))
}

</script>