<script setup>

import { onMounted, ref, markRaw } from 'vue'

import { IconMenu2, IconShare2 } from '@tabler/icons-vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

import nativeShare from '../../content/nativeShare.js'

import Menu from './Menu.vue'

import { useRoute } from 'vue-router'
const route = useRoute()

const isShareSupported = ref(false)


onMounted(() => {
    isShareSupported.value = !!navigator.share
})



async function shareNative() {
    if (navigator.share) {

        const appUrl = import.meta.env.VITE_APP_NAME_URL
        await navigator.share({
            title: `Customisable, print-at-home Women's Euro 2025 Wallchart supporting Médecins Sans Frontières, in your timezone and language.`,
            text: `Customisable, print-at-home Women's Euro 2025 Wallchart supporting Médecins Sans Frontières, in your timezone and language. \n\n Make yours now:`,
            url: `/euro2025`,
        })

    } else {
        sendEmail()

    }
}

function sendEmail() {

    const recipient = null
    const subject = encodeURIComponent(`Customisable, print-at-home Women's Euro 2025 Wallchart supporting Médecins Sans Frontières, in your timezone and language. `)
    const body = encodeURIComponent(`Hi! Check out the customisable, print-at-home Women's Euro 2025 Wallcharts supporting Médecins Sans Frontières. They are available in your timezone and language. https://wallcharts.org`)

    const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`

    window.open(mailtoLink, '_blank')
}

</script>

<template>
    <div id="app-top-bar"
        class="fixed top-0 shadow-md w-screen flex items-center justify-between h-12  bg-white bg-opacity-80 backdrop-blur-sm z-30 ">

        <div id="top-bar-left-group" class="pl-2 w-full">
            <button aria-label="open menu" @click="ui.openRightTray(markRaw(Menu))" class="w-14 h-12 mb-1">
                <IconMenu2 class="h-8 w-8 mt-1.5 text-sky-800" />
            </button>
        </div>
        <router-link  aria-label="go to home" :to="{name: 'root'}" class="w-full flex items-center justify-center ">
            <img alt="blue box of the wallcharts.org logo" src="/wallcharts_logo.png" class="w-36" />
        </router-link>

        <div id="top-bar-right-group"
            class="flex z-10 w-full items-center justify-end  text-sm sm:text-base max-h-8 overflow-x-visible space-x-1 ">

            <button aria-label="share wallcharts.org" class="flex items-center justify-between py-1 px-3 h-12 text-sky-800 mb-1" @click="shareNative()">
                <IconShare2 class="h-6 w-6 text-sky-800" />
            </button>
        </div>
    </div>
</template>
