<script setup>

import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()

import { computed, markRaw } from 'vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

import EmailSubscriber from '@shared/components/EmailSubscriber.vue'
import Feedback from '../components/Feedback.vue'
import about from './AboutContent.json'


const showFeedback = () => {
    ui.openRightTray(markRaw(Feedback))
}



</script>

<template>

    <div class="w-full text-sky-800 mt-8 flex flex-col justify-start items-center max-w-3xl  px-4 md:p-0 space-y-6">


        <div id="intro_section" class="w-full  flex flex-col mt-6 ">
            <h1 class="text-4xl font-semibold">
                {{ about[language.uiLang].title }}

            </h1>
            <div class="w-full  flex  mt-4">


                <div class=" space-y-2  w-full  ">
                    <p v-for="(p, i) in about[language.uiLang].section1.paragraphs" :key="i" v-html="p"></p>
                </div>


            </div>

        </div>

        <div id="mailing_list" class=" w-full  items-center justify-center space-y-4">

            <div class="w-full flex items-center justify-start">
                <div class="text-2xl font-semibold">
                    {{ about[language.uiLang].section2.subtitle }}
                </div>
            </div>

            <EmailSubscriber />
        </div>


    </div>



</template>
