<script setup>

import heroContent from '../content/heroContent.js'

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

function goToMSF() {
    window.open('https://www.msf.org/')
}

</script>

<template>

    <button aria-label="Go to Médecins Sans Frontières website" @click="goToMSF()" class="flex items-center justify-center w-full px-2 -mr-3">

        <div class="text-xxs text-sky-800 sm:text-sm items-center flex"

                :aria="language.getAriaElement(heroContent, 'HeroSupportingLine')">

                {{ language.getContentElement(heroContent, 'HeroSupportingLine') }}

        </div>
        <div class="flex items-center justify-center  ">

            <!-- <div class="text-base font-bold flex ">Médecins Sans Frontières</div> -->
            <img alt="Médecins Sans Frontières logo, red stripes and person"src="/msf_logo.svg" class="h-8 w-20" />
        </div>
    </button>
</template>