<script setup>

import { ref, onMounted, markRaw } from 'vue'

import { useSubscriberStore } from '@shared/stores/subscriber.js'
const sub = useSubscriberStore()

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import Privacy from '@shared/components/Privacy.vue'

import ThreeDots from '@shared/components/ThreeDots.vue'


import content from '@shared/content/content.js'

import { IconTrash, IconCheck, IconX } from '@tabler/icons-vue'

import { XCircleIcon } from '@heroicons/vue/24/outline'



const isLoading = ref(false)

const userContent = getContent()

function getContent() {
    const defaultLang = 'en'
    const userLang = navigator.language
    if (content[userLang]) {
        return content[userLang]['subscriber']
    } else
        return content[defaultLang]['subscriber']
}


async function dispatchCreateSubscriber() {

    isLoading.value = true

    const resp = await sub.createSubscriber(sub.subscriberEmail)
    if (resp) {
        sub.subscriberEmail = ''
    }

    isLoading.value = false

}

async function dispatchRemoveSubscriber() {

    const unsubCode = localStorage.getItem('unsubCode')
    const resp = await sub.removeSubscriber(unsubCode)
    if (resp) {
        sub.subscriberEmail = null
    }


}

const deleteConfirm = ref(false)


</script>

<template>
    <div v-if="!isLoading" class="w-full">
        <div v-if="!sub.isSubscribed" class="w-full flex flex-col justify-center items-start">
            <form @submit.prevent="onSubmit" class="w-full relative flex space-x-3">
                <input type="email" name="email" id="subscriber_email" :placeholder="userContent.placeholder" required
                    autocomplete="email" aria-label="Subscriber email address"
                    class="w-full h-12 border border-sky-800 rounded-md shadow-lg px-4 text-sky-800 focus:outline-none placeholder:text-sm placeholder:font-light placeholder:text-sky-800"
                    v-model="sub.subscriberEmail">

                </input>
                <button v-if="sub.subscriberEmail" @click="sub.subscriberEmail = null"
                    class="absolute top-0  right-3 flex items-center justify-end min-w-10 h-full ">

                    <XCircleIcon class=" h-5 w-5 text-sky-800" />
                </button>
            </form>
            <button @click="dispatchCreateSubscriber"
                class="w-full h-12  rounded-md mt-2 text-white bg-sky-800 text-lg font-medium px-4   flex justify-center items-center">

                <span>{{ userContent.subscribe }}</span>
            </button>
            <div class="text-xxs text-sky-800 mt-1 pl-2">{{ userContent.privacy }} <button class="underline"
                    @click="ui.openRightTray(markRaw(Privacy))">{{ userContent.privacyPolicy }}</button></div>
        </div>
        <div v-else
            class="w-full text-sky-800 font-medium text-base flex flex-col items-center justify-center space-y-4 ">

            <div class="text-center">

                Thank you for choosing to stay in touch by email.
            </div>

            <div class="relative text-base font-medium h-14 rounded-md border w-full shadow-lg border-sky-800 px-2">

                <button @click="deleteConfirm=true" class=" w-full h-full">
                    Unsubscribe
                </button>
                <div v-if="deleteConfirm"
                    class="absolute top-0 left-0 w-full h-full border-2 border-red-600 text-sky-800  bg-white opacity-95 rounded-md shadow-lg flex text-sm items-center justify-evenly">
                    <button class="h-full  w-1/5 flex items-center justify-center" @click="dispatchRemoveSubscriber()">
                        <IconCheck class="h-8 w-8" />
                    </button>
                    <div class="font-medium  text-center">Unsubscribe from emails?</div>
                    <button class="h-full  w-1/5 flex items-center justify-center" @click="deleteConfirm = false">
                        <IconX class="h-8 w-8" />
                    </button>
                </div>
            </div>


        </div>
    </div>
    <div v-else>
        <ThreeDots />
    </div>
</template>
