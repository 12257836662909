<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

import ThreeDots from '@shared/components/ThreeDots.vue'


import { useChartStore } from '../../stores/chart.js'
const chart = useChartStore()

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

const loadingMessages = [
    "Building your chart... 😎",
    "This takes a few seconds... ⏱️",
    "Stacking up the PDFs... 📑",
    "Creating your unique sharing link... 🔗",
    "Making sure the PDFs look right... 🔎",
    "Finding out what's taking so long... 🥱",
    "... hmmm... 🧐",
    "Houston, we may have a problem... 😱",
    "Really sorry to say this is not looking good. Please try again later. 😔"
]

const displayMessage = ref(loadingMessages[0]) // Start with first message
let intervalId = null
let elapsedTime = 0 // Track how long the loop has been running

function loopMessages() {
    let index = 0
    elapsedTime = 0

    intervalId = setInterval(() => {
        elapsedTime += 2000; // Increment by 1.5 seconds
        if (elapsedTime >= 18000) { // Stop after 30 seconds - matched to axios
            clearInterval(intervalId)
            chart.chartId = null
            chart.chartLoading = false
            ui.showMessage('Really sorry, something went wrong during your chart build. :(')
            return
        }

        index = (index + 1) % loadingMessages.length // Loop through messages
        displayMessage.value = loadingMessages[index]
    }, 2000)
}

// Start loop on mount
onMounted(() => {
    loopMessages()
})

// Cleanup on unmount (good practice)
onUnmounted(() => {
    if (intervalId) clearInterval(intervalId)
})
</script>


<template>

    <div class="h-96 w-full flex flex-col items-center justify-center space-y-4 text-sky-800 border border-sky-800 rounded-md shadow-lg mt-4">

        <ThreeDots/>
        <div id="messages" class="w-full flex items-center justify-center">
            {{  displayMessage }}
        </div>
    </div>

</template>