<script setup>

import { markRaw } from 'vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { useVersionStore } from '../../stores/version.js'
const version = useVersionStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()


import EmailSubscriber from '@shared/components/EmailSubscriber.vue'

import Privacy from '@shared/components/Privacy.vue'

import ChartList from '../charts/ChartList.vue'

</script>

<template>
    <div class="flex flex-col items-center  justify-evenly h-[600px] w-full md:w-2/5 space-y-6 ">

        <div class="w-full flex flex-col items-center justify-center text-sky-800 space-y-4 font-bold ">

            <div class="w-full text-center">
                <router-link :to="{ name: 'euro2025' }" @click="ui.closeRightTray()">
                    Euro 2025
                </router-link>
            </div>
            <div class="w-full text-center">
                <button @click="ui.openRightTray(markRaw(ChartList))">
                    My Charts
                </button>
            </div>
            <div class="w-full text-center">
                <router-link :to="{ name: 'root' }" @click="ui.closeRightTray()">
                    All tournaments
                </router-link>
            </div>
            <div class="w-full text-center">
                <router-link :to="{ path: `/${language.uiLang}/about` }" @click="ui.closeRightTray()">
                    About this project
                </router-link>
            </div>
            <div class="w-full text-center">
                <router-link :to="{ path: `/${language.uiLang}/msf` }" @click="ui.closeRightTray()">
                    About Médecins Sans Frontières
                </router-link>
            </div>
            <div class="w-full text-center">
                <router-link :to="{ path: `/${language.uiLang}/faq` }" @click="ui.closeRightTray()">
                    FAQs
                </router-link>
            </div>
            <div class="w-full text-center">
                <button @click="ui.openRightTray(markRaw(Privacy))">
                    Privacy and Terms
                </button>
            </div>



        </div>



        <EmailSubscriber />
        <div class="w-full text-xxs text-sky-800 text-center mt-12">Version: {{ version.currentVersion }}</div>
    </div>


</template>
