
import { defineStore } from "pinia"
import semver from 'semver'

export const useVersionStore = defineStore('versionStore', () => {

    const currentVersion = "0.3.1" // versioning start 9 Feb 25

    function checkAndUpdateVersion() {

        const lastVersion = localStorage.getItem('lastVersion')

        if (!semver.valid(currentVersion)) {
            console.warn("Invalid version number in state.")
            return
        }

        if (!lastVersion) {
            localStorage.setItem('lastVersion', currentVersion)
            return
        }

        if (semver.eq(lastVersion, currentVersion)) {
            return
        }

        if (semver.lt(lastVersion, currentVersion)) {
            const diff = semver.diff(lastVersion, currentVersion)
            try {
                handleUpdate(diff, lastVersion, currentVersion)
                localStorage.setItem('lastVersion', currentVersion)
                return

            } catch(error) {
                console.log("update error")
            }
        }
    }

    function handleUpdate(diff, lastVersion, currentVersion) {
        switch(diff) {
            case "major":
                console.info("%cYour app was updated to new major version!", "color: green; font-weight: bold;")
                break
            case "minor":
                const lastVersionMinor = semver.minor(lastVersion)
                if (lastVersionMinor === 2 || lastVersionMinor === 1) {
                    localStorage.clear()
                }
                console.info("%cYour app was updated to new minor version!", "color: green; font-weight: bold;")
                break
            case "patch":
                console.info("%cYour app was updated to new patch version!", "color: green; font-weight: bold;")
                break
            default:
                console.error("swtich cases on update did not work")
        }
    }

    return {
        checkAndUpdateVersion,
        currentVersion
    }
})
