<script setup>

import { markRaw, onMounted, ref } from 'vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import BracketChart from './BracketChart.vue'
import ScheduleChart from './ScheduleChart.vue'
import FactsChart from './FactsChart.vue'
import GamesChart from './GamesChart.vue'
import ChartPreviewFrame from './ChartPreviewFrame.vue'

import { IconZoomIn } from '@tabler/icons-vue'


const inView = ref('Bracket')

const pages = ['Bracket', 'Schedule']

function selectPage(page) {
    inView.value = page
}

</script>

<template>
    <div class="flex flex-col items-center justify-center w-full h-full ">


        <div id="page-selector" class=" flex items-center justify-evenly space-x-4 w-full">
            <button aria-label="select the chart layour" v-for="(page, index) in pages" :key="index" @click="selectPage(page)" class="h-9">
                <div :class="[
                    'px-2 py-0.5 text-xs sm:text-sm rounded-sm ',
                    inView === page ? 'bg-sky-800 text-white shadow-md' : 'bg-white text-sky-800'
                ]">
                    {{ page }}
                </div>
            </button>
        </div>


        <button aria-label="preview your chart" @click="ui.openRightTray(markRaw(ChartPreviewFrame))"
            class="flex justify-start items-center  w-full  shadow-md rounded-md relative  bg-white border border-sky-800  flex-shrink-0 object-contain">
            <div class="w-full h-full flex justify-center items-center ">
                <BracketChart v-if="inView === 'Bracket'" class="w-full h-full object-contain" />
                <ScheduleChart v-if="inView === 'Schedule'" class="w-full h-full object-contain" />
                <FactsChart v-if="inView === 'Facts'" class="w-full h-full object-contain" />
                <GamesChart v-if="inView === 'Games'" class="w-full h-full object-contain" />
            </div>
            <div
                class="absolute bottom-2 left-2 sm:bottom-4 sm:left-4 flex items-center justify-center  rounded-full  p-2  bg-sky-800 ">
                <IconZoomIn class="text-white w-7 h-7" />
            </div>
        </button>
    </div>
</template>
