import { createRouter, createWebHistory } from "vue-router"
import Euro2025View from '../views/Euro2025View.vue'
import Upcoming from '../components/charts/Upcoming.vue'
import AboutView from '../views/AboutView.vue'
import RootView from '../views/RootView.vue'
import FAQView from '../views/FAQView.vue'
import MSFView from '../views/MSFView.vue'
import QrRedirect from '../views/QrRedirect.vue'
import NotFound from '../views/NotFound.vue'

import { useSubscriberStore } from '@shared/stores/subscriber.js'
import { useLanguageStore } from '@shared/stores/language.js'
import { useChartStore } from '../stores/chart.js'


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  routes: [
    {
      path: "/",
      name: "root",
      component: RootView,
      meta: {
        'title': "Customisable, Print-At-Home Tournament Fixture Charts in your Timezone and Language that support Médecins Sans Frontières.",
        "description": "Create free print-at-home tournament fixture charts for the world's biggest football events in your timezone & language: no sign-ups, no ads, no paywall, no cookies. Fully customizable & easy to use.",
        "image": "https://wallcharts.org/wallcharts_logo.png"
      }
    },
    {
      path: "/euro2025/:chartId?",
      name: "euro2025",
      component: Euro2025View,
      beforeEnter: async (to, from, next) => {
        const chart = useChartStore()
        const chartId = to.params.chartId
        if (chartId) {


          // check LS
          const chartInLS = chart.findChart(chartId)
          if (chartInLS) {
            chart.setChartState(chartInLS)


          } else {
            chart.chartGetInProgress = true
            await chart.getChart(chartId)
            chart.chartGetInProgress = false
          }

          next({ path: "/euro2025" })
          return
        }
        next()
      },
      meta: {
        'title': "Women's Euro 2025 - Customisable, Print-At-Home Tournament Fixture Charts in your Timezone and Language that support Médecins Sans Frontières.",
        "description": "Create free print-at-home tournament fixture charts for the Women's Euros 2025 in your timezone & language—no sign-ups, no ads, no paywall. Fully customizable & easy to use.",
        "image": "https://wallcharts.org/wallcharts_logo.png"
      }
    },
    {
      path: '/afc2025',
      name: 'afc2025',
      component: Upcoming,
      meta: {
        'title': "Men's African Cup 2025/26 - Customisable, Print-At-Home Tournament Fixture Charts in your Timezone and Language that support Médecins Sans Frontières.",
        "description": "Create free print-at-home tournament fixture charts for the Men's African Cup 2025/26 in your timezone & language—no sign-ups, no ads, no paywall. Fully customizable & easy to use.",
        "image": "https://wallcharts.org/wallcharts_logo.png"
      },
      props: { competition: "Men's African Cup of Nations 2025", location: "Morocco", date: "December 2025-January 2026", timezone: 'UTC+1' }
    },
    {
      path: '/wc2026',
      name: 'wc2026',
      component: Upcoming,
      meta: {
        'title': "Men's Worls Cup 2026 - Customisable, Print-At-Home Tournament Fixture Charts in your Timezone and Language that support Médecins Sans Frontières.",
        "description": "Create free print-at-home tournament fixture charts for the Men's World Cup 2026 in your timezone & language—no sign-ups, no ads, no paywall. Fully customizable & easy to use.",
        "image": "https://wallcharts.org/wallcharts_logo.png"
      },
      props: { competition: "Men's World Cup 2026", location: "United States, Canada, Mexico", date: "July 2026", timezone: 'UTC-4, UTC-5, UTC-6' }
    },
    {
      path: '/ac2027',
      name: 'ac2027',
      component: Upcoming,
      meta: {
        'title': "Men's Asian Cup 2027 - Customisable, Print-At-Home Tournament Fixture Charts in your Timezone and Language that support Médecins Sans Frontières.",
        "description": "Create free print-at-home tournament fixture charts for the Men's Asian Cup 2027 in your timezone & language—no sign-ups, no ads, no paywall. Fully customizable & easy to use.",
        "image": "https://wallcharts.org/wallcharts_logo.png"
      },
      props: { competition: "Men's Asian Cup 2027", location: "Saudi Arabia", date: "January - February 2027", timezone: 'UTC+3' }
    },
    {
      path: '/wc2027',
      name: 'wc2027',
      component: Upcoming,
      meta: {
        'title': "Women's World Cup 2027 - Customisable, Print-At-Home Tournament Fixture Charts in your Timezone and Language that support Médecins Sans Frontières.",
        "description": "Create free print-at-home tournament fixture charts for the Women's World Cup 2027 in your timezone & language—no sign-ups, no ads, no paywall. Fully customizable & easy to use.",
        "image": "https://wallcharts.org/wallcharts_logo.png"
      },
      props: { competition: "Women's World Cup 2027", location: "Brazil", date: "June - July 2027", timezone: 'UTC-3, UTC-4' }
    },
    {
      path: "/email",
      name: "email",
      component: Euro2025View,
      beforeEnter: async (to, from, next) => {

        const sub = useSubscriberStore()

        const confirmCode = to.query.confirm_code
        const unsubCode = to.query.unsub_code

        if (confirmCode) {
          try {
            await sub.confirmSubscriber(confirmCode, import.meta.env.VITE_APP_ID)
            next({ path: '/' })
            return
          } catch (error) {
            console.error('Router: Error confirming subscriber:', error)
          }
        }

        if (unsubCode) {
          try {
            await sub.removeSubscriber(unsubCode, import.meta.env.VITE_APP_ID)
            sub.subscriberEmail = null
            next({ path: '/' })
            return
          } catch (error) {
            console.error('Router: Error removing subscriber:', error)
          }
        }


        next() // Proceed to the rootveiw component
      }
    },
    {
      path: "/euro2025/qr",
      name: "qr",
      component: QrRedirect,
    },
    {
      path: "/about",
      redirect: (to) => {
        const language = useLanguageStore();
        const uiLang = language.uiLang; // Default to English if no preference
        return `/${uiLang}/about`; // Redirect to user's preferred language
      }
    },
    {
      path: "/:lang(en|fr)/about",
      name: "about",
      component: AboutView,
      meta: {
        'title': "About Wallcharts.org – Print-Friendly Tournament Charts",
        "description": "Discover Wallcharts.org, a project focused on customizable, print-at-home tournament Fixture charts with no tracking, ads, or paywalls.",
        "image": "https://wallcharts.org/wallcharts_logo.png"
      }
    },
    {
      path: "/:lang(en|fr)/msf",
      name: "msf",
      component: MSFView,
      meta: {
        'title': "Médecins Sans Frontières – Supporting Life-Saving Work",
        "description": "Learn how Wallcharts.org supports Médecins Sans Frontières (Doctors Without Borders) and their mission to provide medical aid where it's needed most.",
        "image": "https://wallcharts.org/wallcharts_logo.png"
      }
    },
    {
      path: "/:lang(en|fr)/faq",
      name: "faq",
      component: FAQView,
      meta: {
        'title': " FAQs – Wallcharts.org Tournament Charts",
        "description": "Get answers to common questions about our timezone and language adjusted, customizable tournament charts. No sign-ups, no cookies, just simple chart generation.",
        "image": "https://wallcharts.org/wallcharts_logo.png"
      }
    },
    {
      path: "/privacy-terms",
      name: "privacyTerms",
      component: AboutView,
      meta: {
        'title': "Privacy & Terms – No Tracking, No Cookies | Wallcharts.org",
        "description": "Your privacy matters. Wallcharts.org is cookie-free, ad-free, and tracking-free. Read our commitment to ethical web practices.",
        "image": "https://wallcharts.org/wallcharts_logo.png"
      }
    },
    {
      path: "/site-images/:filename",
      name: "siteImage",
      beforeEnter: (to, from, next) => {
        const validImages = ["wallcharts_logo.png"] // Add all static images
        if (validImages.includes(to.params.filename)) {
          window.location.href = `/${to.params.filename}`
        } else {
          next({ name: "NotFound" }) // Redirect to 404 if image doesn't exist
        }
      }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFound,
      meta: {
        title: "404 Not Found – Wallcharts.org",
        description: "Oops! The page you’re looking for doesn’t exist. Explore our timezone and language adjusted, customizable tournament charts instead."
      },
      beforeEnter: (to, from, next) => {
        // Allow static files (images, icons, fonts) to load from /public/
        const allowedExtensions = ['.png', '.jpg', '.jpeg', '.svg', '.xml', '.txt']
        if (allowedExtensions.some(ext => to.path.endsWith(ext))) {
          window.location.href = to.path // Let the browser request the file normally
          return
        }

        // If not a static file, show 404 page
        next()
      }
    },
  ],
})

const noindexRoutes = ["/email", "/euro2025/qr"]


router.beforeEach((to, from, next) => {
  if (noindexRoutes.includes(to.path)) {
    document.head.querySelector('meta[name="robots"]')?.setAttribute("content", "noindex, nofollow")
  } else {
    document.head.querySelector('meta[name="robots"]')?.setAttribute("content", "index, follow")
  }

  // Ensure page title is set
  if (to.meta.title) {
    document.title = to.meta.title
    document.querySelector('meta[property="og:title"]')?.setAttribute("content", to.meta.title)
    document.querySelector('meta[name="twitter:title"]')?.setAttribute("content", to.meta.title)
  }

  // Ensure meta description exists
  let metaDescription = document.querySelector('meta[name="description"]')
  if (!metaDescription) {
    metaDescription = document.createElement("meta")
    metaDescription.name = "description"
    document.head.appendChild(metaDescription)
  }
  metaDescription.setAttribute("content", to.meta.description || "")

  document.querySelector('meta[property="og:description"]')?.setAttribute("content", to.meta.description)
  document.querySelector('meta[name="twitter:description"]')?.setAttribute("content", to.meta.description)

  // Set OG & Twitter URL
  document.querySelector('meta[property="og:url"]')?.setAttribute("content", window.location.href)
  document.querySelector('meta[name="twitter:url"]')?.setAttribute("content", window.location.href)

  // Set Open Graph & Twitter Image
  const defaultImage = "https://wallcharts.org/wallcharts_logo.png"
  const image = to.meta.image || defaultImage
  document.querySelector('meta[property="og:image"]')?.setAttribute("content", image)
  document.querySelector('meta[name="twitter:image"]')?.setAttribute("content", image)

  // Set Twitter Card Type
  const cardType = to.meta.image ? "summary_large_image" : "summary"
  document.querySelector('meta[name="twitter:card"]')?.setAttribute("content", cardType)

  next()
})

router.beforeEach((to, from, next) => {
  const language = useLanguageStore()
  const uiLang = language.uiLang


  // if there no lang path param, and the path is not the root, and the path doesn talready start with the user lang
  if (!to.params.lang && to.path !== "/" && !to.path.startsWith(`/${uiLang}`)) {
    return next({ path: `/${uiLang}${to.path}` })
    }

  if (to.params.lang && to.params.lang !== uiLang) {
    console.log('ui lang changed to', to.params.lang)
    language.changeUiLang(to.params.lang)

  }
  return next()

})

export default router
