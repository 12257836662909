<script setup>

import { useRouter } from 'vue-router'
const router = useRouter()

import { markRaw } from 'vue'

import { ArrowsPointingOutIcon, ArrowTopRightOnSquareIcon, ClockIcon, PrinterIcon, CreditCardIcon, NoSymbolIcon, LanguageIcon, EyeIcon, QrCodeIcon, ChartBarIcon } from '@heroicons/vue/24/outline'
import { IconBulb, IconBallFootball, IconCode } from '@tabler/icons-vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import EmailSubscriber from '@shared/components/EmailSubscriber.vue'
import Button from '@shared/components/Button.vue'
import Feedback from '../components/Feedback.vue'

function donate() {
    window.open('https://www.msf.org/donate')
}



</script>

<template>

    <div class="w-full  text-sky-800 mt-8 flex flex-col justify-start items-center max-w-3xl  px-4 md:p-0 space-y-12">
        <div class="w-full  flex flex-col mt-6">
            <div class="text-4xl font-semibold">
                <p>
                    About Médecins Sans Frontières
                </p>
            </div>
            <div class="w-full  flex flex-col space-y-4  mt-4">


                <div class=" space-y-2  w-full  ">

                    <p class="">

                        Médecins Sans Frontières/Doctors Without Borders acts fast to save lives in conflict zones,
                        natural disaters and epidemics.
                    </p>
                    <p class="">

                        They are funded by people like us. Rarely by governments. So they are free to act fast.
                    </p>
                    <p>In a conflict, they don't take sides. Their teams go where the medical need is greatest.</p>
                    <p>They provide free medical care. Regardless of a person's ethnicity, religion or politics.</p>



                </div>




            </div>

        </div>

        <div class="w-full max-w-2xl mx-auto aspect-video rounded-lg overflow-hidden">
            <iframe src="https://www.youtube.com/embed/YC2HH19Pznc?si=u3gWKbhSkk50lSnY"
                title="YouTube video player" class="w-full h-full" frameborder="0" allow="autoplay; web-share"
                allowfullscreen>
            </iframe>
        </div>

        <div class=" space-y-2  w-full  ">


            <button @click="donate()"
                class="shadow-lg font-medium text-lg bg-sky-800 text-white h-14 w-full rounded-md border border-sky-800">
                Donate and help save lives
            </button>

        </div>

    </div>



</template>

