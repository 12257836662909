import { ref, watch } from "vue"
import { defineStore } from "pinia"
import { useLocationStore } from '@shared/stores/location.js';
import { useChartStore } from './chart.js'

import { translatedTeams } from '../content/teamNames.js'

import { DateTime } from 'luxon'

export const useScheduleStore = defineStore('scheduleStore', () => {

    const location = useLocationStore()
    const chart = useChartStore()


    const gs = ref({
        m1: {
            gameNumber: '#1', // for reference only, will hardoce on designs
            city: 'Basel',
            stadium: 'St. Jakob-Park',
            home: 'SWITZERLAND',
            away: 'NORWAY',
            gameUTCDateTime: '2025-07-02T19:00:00Z',
            home_translated: '', // these are defaults
            away_translated: '',
            gameAdjustedDateTime: '', // this creates the offset adjusted datetime
            game_date: '', // this holds the language specifc trnalsaiotn of the adjusted offset
            game_time: '' // this holds the language specifc trnalsaiotn of the adjusted offset
        },
        m2: {
            gameNumber: '#2',
            city: 'Thun',
            stadium: 'Arena Thun',
            home: 'ICELAND',
            away: 'FINLAND',
            gameUTCDateTime: '2025-07-02T16:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m3: {
            gameNumber: '#3',
            city: 'Bern',
            stadium: 'Stadion Wankdorf',
            home: 'SPAIN',
            away: 'PORTUGAL',
            gameUTCDateTime: '2025-07-03T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m4: {
            gameNumber: '#4',
            city: 'Sion',
            stadium: 'Stade de Tourbillon',
            home: 'BELGIUM',
            away: 'ITALY',
            gameUTCDateTime: '2025-07-03T16:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m5: {
            gameNumber: '#5',
            city: 'St.Gallen',
            stadium: 'Arena St.Gallen',
            home: 'GERMANY',
            away: 'POLAND',
            gameUTCDateTime: '2025-07-04T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m6: {
            gameNumber: '#6',
            city: 'Geneva',
            stadium: 'Stade de Genève',
            home: 'DENMARK',
            away: 'SWEDEN',
            gameUTCDateTime: '2025-07-04T16:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m7: {
            gameNumber: '#7',
            city: 'Zurich',
            stadium: 'Stadion Letzigrund',
            home: 'FRANCE',
            away: 'ENGLAND',
            gameUTCDateTime: '2025-07-05T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m8: {
            gameNumber: '#8',
            city: 'Lucerne',
            stadium: 'Allmend Stadion Luzern',
            home: 'WALES',
            away: 'NETHERLANDS',
            gameUTCDateTime: '2025-07-05T16:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m9: {
            gameNumber: '#9',
            city: 'Bern',
            stadium: 'Stadion Wankdorf',
            home: 'SWITZERLAND',
            away: 'ICELAND',
            gameUTCDateTime: '2025-07-06T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m10: {
            gameNumber: '#10',
            city: 'Sion',
            stadium: 'Stade de Tourbillon',
            home: 'NORWAY',
            away: 'FINLAND',
            gameUTCDateTime: '2025-07-06T16:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m11: {
            gameNumber: '#11',
            city: 'Geneva',
            stadium: 'Stade de Genève',
            home: 'PORTUGAL',
            away: 'ITALY',
            gameUTCDateTime: '2025-07-07T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m12: {
            gameNumber: '#12',
            city: 'Thun',
            stadium: 'Arena Thun',
            home: 'SPAIN',
            away: 'BELGIUM',
            gameUTCDateTime: '2025-07-07T16:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m13: {
            gameNumber: '#13',
            city: 'Basel',
            stadium: 'St. Jakob-Park',
            home: 'GERMANY',
            away: 'DENMARK',
            gameUTCDateTime: '2025-07-08T16:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m14: {
            gameNumber: '#14',
            city: 'Basel',
            stadium: 'St. Jakob-Park',
            home: 'POLAND',
            away: 'SWEDEN',
            gameUTCDateTime: '2025-07-08T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m15: {
            gameNumber: '#15',
            city: 'Zurich',
            stadium: 'Stadion Letzigrund',
            home: 'ENGLAND',
            away: 'NETHERLANDS',
            gameUTCDateTime: '2025-07-09T16:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m16: {
            gameNumber: '#16',
            city: 'St.Gallen',
            stadium: 'Arena St.Gallen',
            home: 'FRANCE',
            away: 'WALES',
            gameUTCDateTime: '2025-07-09T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m17: {
            gameNumber: '#17',
            city: 'Geneva',
            stadium: 'Stade de Genève',
            home: 'FINLAND',
            away: 'SWITZERLAND',
            gameUTCDateTime: '2025-07-10T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m18: {
            gameNumber: '#18',
            city: 'Thun',
            stadium: 'Arena Thun',
            home: 'NORWAY',
            away: 'ICELAND',
            gameUTCDateTime: '2025-07-10T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m19: {
            gameNumber: '#19',
            city: 'Bern',
            stadium: 'Stadion Wankdorf',
            home: 'ITALY',
            away: 'SPAIN',
            gameUTCDateTime: '2025-07-11T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m20: {
            gameNumber: '#20',
            city: 'Sion',
            stadium: 'Stade de Tourbillon',
            home: 'PORTUGAL',
            away: 'BELGIUM',
            gameUTCDateTime: '2025-07-11T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m21: {
            gameNumber: '#21',
            city: 'Zurich',
            stadium: 'Stadion Letzigrund',
            home: 'SWEDEN',
            away: 'GERMANY',
            gameUTCDateTime: '2025-07-12T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m22: {
            gameNumber: '#22',
            city: 'Lucerne',
            stadium: 'Allmend Stadion Luzern',
            home: 'POLAND',
            away: 'DENMARK',
            gameUTCDateTime: '2025-07-12T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m23: {
            gameNumber: '#23',
            city: 'Basel',
            stadium: 'St. Jakob-Park',
            home: 'NETHERLANDS',
            away: 'FRANCE',
            gameUTCDateTime: '2025-07-13T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m24: {
            gameNumber: '#24',
            city: 'St.Gallen',
            stadium: 'Arena St.Gallen',
            home: 'ENGLAND',
            away: 'WALES',
            gameUTCDateTime: '2025-07-13T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m25: {
            gameNumber: '#25',
            city: 'Geneva',
            stadium: 'Stade de Genève',
            home: '',
            away: '',
            gameUTCDateTime: '2025-07-16T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m26: {
            gameNumber: '#26',
            city: 'Zurich',
            stadium: 'Stadion Letzigrund',
            home: '',
            away: '',
            gameUTCDateTime: '2025-07-17T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m27: {
            gameNumber: '#27',
            city: 'Bern',
            stadium: 'Stadion Wankdorf',
            home: '',
            away: '',
            gameUTCDateTime: '2025-07-18T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m28: {
            gameNumber: '#28',
            city: 'Basel',
            stadium: 'St. Jakob-Park',
            home: '',
            away: '',
            gameUTCDateTime: '2025-07-19T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m29: {
            gameNumber: '#29',
            city: 'Geneva',
            stadium: 'Stade de Genève',
            home: '',
            away: '',
            gameUTCDateTime: '2025-07-22T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m30: {
            gameNumber: '#30',
            city: 'Zurich',
            stadium: 'Stadion Letzigrund',
            home: '',
            away: '',
            gameUTCDateTime: '2025-07-23T19:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m31: {
            gameNumber: '#31',
            city: 'Basel',
            stadium: 'St. Jakob-Park',
            home: '',
            away: '',
            gameUTCDateTime: '2025-07-27T16:00:00Z',
            home_translated: '',
            away_translated: '',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
    })

    const adjustGameDateTime = (gameUTCDateTime) => {

        const adjusted = DateTime.fromISO(gameUTCDateTime, { zone: 'utc' }).setZone(location.selectedTimezone) // this is ok to use

        return adjusted
    }

    function translateTeam(teamName, lang) {

        try {
            return translatedTeams[teamName][lang]
        } catch {
            return teamName
        }
    }



    function localiseGameSchedule() {
        Object.keys(gs.value).forEach(key => {

            gs.value[key]['gameAdjustedDateTime'] = adjustGameDateTime(gs.value[key]['gameUTCDateTime'])
            gs.value[key]['home_translated'] = translateTeam(gs.value[key]['home'], chart.chartLang)
            gs.value[key]['away_translated'] = translateTeam(gs.value[key]['away'], chart.chartLang)
            gs.value[key]['game_date'] = gs.value[key]['gameAdjustedDateTime'].setLocale(chart.chartLang).toLocaleString({month: 'short', day: 'numeric', weekday: 'short'})
            gs.value[key]['game_time'] = gs.value[key]['gameAdjustedDateTime'].setLocale(chart.chartLang).toLocaleString(DateTime.TIME_SIMPLE )
        })

        
    }



    watch(() => [chart.chartLang, location.selectedTimezone], (newVal, oldVal) => {
        localiseGameSchedule()
    })

    return {
        gs,
        localiseGameSchedule
    }
})