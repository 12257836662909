import { ref } from "vue"
import { defineStore } from "pinia"
import sharedApi from '../api/sharedApi.js'
import { apiError } from '../api/apiError.js'

import { useUIStore } from './ui.js'



export const useSubscriberStore = defineStore('subscriberStore', () => {
    const ui = useUIStore()

    const isSubscribed = ref(false)

    const subscriberEmail = ref(null)

    async function createSubscriber(email) {
        try {
            const resp = await sharedApi.postCreateSubscriber(email)
            if (resp) {
                localStorage.setItem('unsubCode', resp.data.data['unsub_code'])
                isSubscribed.value = true
            }
        } catch (error) {
            apiError(error, ui)
        }
    }

    async function removeSubscriber(unsubCode) {
        try {
            const resp = await sharedApi.deleteRemoveSubscriber(unsubCode)
            if (resp) {
                localStorage.removeItem('unsubCode')
                isSubscribed.value = false

            }
        } catch (error) {
            apiError(error, ui)
        }
    }

    async function checkSubscriber() {
        const isSub = localStorage.getItem('unsubCode')

        if (isSub) {
            isSubscribed.value = true
        }
    }


    return {
        createSubscriber,
        removeSubscriber,
        checkSubscriber,
        isSubscribed,
        subscriberEmail
    }

})