import { ref } from "vue"
import { defineStore } from "pinia"
import semver from 'semver'


export const useUIStore = defineStore('uiStore', () => {

    const rightTrayIsOpen = ref(false)
    const rightTrayComponent = ref({})
    const rightTrayComponentProps = ref(null)
    const rightTrayBackNavComponent = ref(null)
    const rightTrayBackNavProps = ref(null)

    const leftTrayIsOpen = ref(false)

    const theme = ref('dusk')




    function openRightTray(component, props, backNavComponent, backNavProps) {
        rightTrayIsOpen.value = true
        rightTrayComponent.value = component
        rightTrayComponentProps.value = props
        rightTrayBackNavComponent.value = backNavComponent
        rightTrayBackNavProps.value = backNavProps
    }

    function closeRightTray() {
        rightTrayIsOpen.value = false
        rightTrayComponent.value = null
        rightTrayComponentProps.value = null
        rightTrayBackNavComponent.value = null
        rightTrayBackNavProps.value = null
    }

    function openLeftTray() {
        closeRightTray()
        leftTrayIsOpen.value = true
    }

    function closeLeftTray() {
        leftTrayIsOpen.value = false
    }

    function readTheme() {
        const localTheme = localStorage.getItem('theme')
        if (localTheme) {
            theme.value = localTheme
        } else {
            theme.value = 'dusk'
        }
    }

    function saveTheme(newTheme) {
        localStorage.setItem('theme', newTheme)
    }

    const uniqueMessages = new Set()
    const messages = ref([])

    function showMessage(message, isError) {
        if (!uniqueMessages.has(message)) {
            uniqueMessages.add(message)

            if (typeof message === 'string') {

                messages.value.push({ message, isError })
            } else {

                messages.value.push(message)
            }

            setTimeout(() => {
                uniqueMessages.delete(message)
                messages.value.shift()
            }, 4000)
        }
    }

    async function sendFeedback(user_feedback) {


        try {
            const resp = await api.postFeedback(user_feedback)
            return true  // essential for the compenent layer to know success
        } catch (error) {
            console.log(error.message)
            const userStore = useUserStore()
        }
    }


    const addStudentIsOpen = ref(false)

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Enables smooth scrolling
        })
    }

    function scrollToSection(sectionId, offset = 50) {
        const element = document.getElementById(sectionId);
        if (element) {
            // element.scrollIntoView({
            //     behavior: "smooth", // Enables smooth scrolling
            //     block: "start" // Scrolls to the top of the element
            // })
            const y = element.getBoundingClientRect().top + window.scrollY + offset;
            window.scrollTo({ top: y, behavior: "smooth" });
        }
    }

    return {

        rightTrayIsOpen,
        rightTrayComponent,
        rightTrayComponentProps,
        rightTrayBackNavComponent,
        rightTrayBackNavProps,
        leftTrayIsOpen,
        openRightTray,
        closeRightTray,
        openLeftTray,
        closeLeftTray,
        readTheme,
        saveTheme,
        showMessage,
        messages,
        uniqueMessages,
        sendFeedback,
        addStudentIsOpen,
        scrollToTop,
        scrollToSection
    }
})