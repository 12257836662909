<script setup>

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

</script>

<template>

    <!-- <div class="text-white w-full p-3 text-sm z-50 flex items-center justify-start rounded-t-md opacity-100"
        :class="isError ? 'bg-red-800': 'bg-green-800'">

                {{ message }}
        </div> -->

    <transition enter-active-class="transition-opacity ease-out duration-1000" enter-from-class="opacity-0"
        enter-to-class="opacity-100" leave-active-class="transition-opacity ease-in duration-1000"
        leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div class="fixed bottom-0 flex-col space-y-1 w-full  bg-gray-800 px-t pt-3 pb-6 text-sm z-50 flex items-center justify-start rounded-t-md opacity-100" v-if="ui.messages.length" >
            <div v-for="message in ui.messages" :class="message.isError ? 'text-red-600': 'text-white'">
                 {{ message.message }}</div>
        </div>
    </transition>
</template>
