<script setup>

import rollbar from '../rollbarClient.js'
import ChartHomeFrame from '../components/charts/ChartHomeFrame.vue'
import ChartCreateForm from '../components/charts/ChartCreateForm.vue'
import ChartLandingFrame from '../components/charts/ChartLandingFrame.vue'
import ChartLoading from '../components/charts/ChartLoading.vue'
import Hero from '../components/Hero.vue'
import LandingHero from '../components/LandingHero.vue'
import ChartList from '../components/charts/ChartList.vue'
import EmailHero from '../components/EmailHero.vue'
import EmailSubscriber from '@shared/components/EmailSubscriber.vue'


import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

import { useChartStore } from '../stores/chart.js'
const chart = useChartStore()

import { useSubscriberStore } from '@shared/stores/subscriber.js'
const subscriber = useSubscriberStore()

import MSFBanner from '../components/MSFBanner.vue'
import BracketChart from '../components/charts/BracketChart.vue'
import ScheduleChart from '../components/charts/ScheduleChart.vue'
// import MSFBanner from '../components/MSFBanner.vue'
// import MSFBanner from '../components/MSFBanner.vue'

import { useHead } from '@vueuse/head'

useHead({
  link: [
    {
      rel: 'canonical',
      href: 'https://wallcharts.org/euro2025'
    }
  ]
})



function scrollToElement(elem) {
  const element = document.getElementById(elem)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', })
  }
}

</script>


<template>
  <div class=" w-full  flex flex-col items-center justify-start  max-w-2xl px-2">
    <div v-show="false">
      <BracketChart />
      <ScheduleChart />
    </div>
    <Hero class="mt-20  " v-if="!chart.chartId" />
    <LandingHero class="mt-20  " v-if="chart.chartId" />
    <MSFBanner class="mt-2" />

    <div id="home-create-section" class="w-screen bg-white -mx-2 pb-6 pt-2 mt-8">

      <div class="max-w-2xl mx-auto px-2">
        <div v-if="!chart.chartId" class="w-full text-3xl text-sky-800 font-bold text-center mt-4">Create your charts
        </div>

        <ChartHomeFrame class="mt-6 " />

        <ChartCreateForm v-if="!chart.chartId && !chart.chartLoading" class="" />

        <ChartLandingFrame v-if="chart.chartId && !chart.chartLoading" class="" />

        <ChartLoading v-if="chart.chartLoading" />

      </div>

    </div>

    <ChartList class="mt-4" />


    <div v-if="!subscriber.isSubscribed"  class="w-full text-3xl text-sky-800 font-bold text-center mt-4">Keep in touch</div>
    <div v-else  class="w-full text-3xl text-sky-800 font-bold text-center mt-4">Thank you</div>


    <EmailSubscriber class=" mt-4 " />

  </div>
</template>
