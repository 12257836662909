import { ref } from "vue"
import { defineStore } from "pinia"


export const useLanguageStore = defineStore('languageStore', () => {



    const supportedLanguages = [
        // { code: "da", name: "Dansk" },
        // { code: "nl", name: "Nederlands" },
        { code: "en", name: "English" },
        // { code: "fi", name: "Suomi" },
        { code: "fr", name: "Français" },
        // { code: "de", name: "Deutsch" },
        // { code: "is", name: "Íslenska" },
        // { code: "it", name: "Italiano" },
        // { code: "no", name: "Norsk" },
        // { code: "pl", name: "Polski" },
        // { code: "pt", name: "Português" },
        // { code: "es", name: "Español" },
        // { code: "sv", name: "Svenska" },
        // { code: "cy", name: "Cymraeg" },
    ]


    const uiLang = ref('en')

    function getContentElement(uiContent, elementName) {
        return uiContent?.[uiLang.value]?.[elementName]?.content ?? null
    }

    function getAriaElement(uiContent, elementName) {
        return uiContent?.[uiLang.value]?.[elementName]?.aria ?? null
    }

    async function getUiLanguage() {
        const uiLangPref = localStorage.getItem("uiLangPref")

        if (uiLangPref) {
            if (supportedLanguages.some(lang => lang.code === uiLangPref)) {
                console.info("%cYour ui language preference was already set, so using that :)", "color: green; font-weight: normal;")



                uiLang.value = uiLangPref



            } else {
                uiLang.value = 'en'
            }
        } else {
            const browserLang = navigator.language || 'en' // first lang in user prefs || hanlde edge case
            const rootLang = browserLang.split('-')[0]
            if (supportedLanguages.includes(rootLang)) {

                // HERE
                uiLang.value = rootLang


            } else {
                uiLang.value = 'en'
            }
        }
    }

    function changeUiLang(newLang) {
        // change state
        uiLang.value = newLang



        // set localStorage pref
        localStorage.setItem('uiLangPref', uiLang.value)
    }





    return {
        uiLang,
        supportedLanguages,
        getUiLanguage,
        getContentElement,
        getAriaElement,
        changeUiLang
    }

})