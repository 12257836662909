export default {
    'en': {
        subscriber: {
            placeholder: "Enter your email",
            subscribe: "Subscribe",
            unsubscribe: "Unsubscribe",
            privacy: "By subscribing you agree to the",
            privacyPolicy: "Privacy Policy",
            pendingConfirmation: "Please click the confirmation link in your email.",
            thanks: "Thanks for being a subscriber!",
            unsubscribed: "Unsubscribed.",
            resubscribe: "Resubscribe"
        }
    },
    'en-GB': {
        subscriber: {
            placeholder: "Enter your email",
            subscribe: "Subscribe",
            unsubscribe: "Unsubscribe",
            privacy: "By subscribing you agree to the",
            privacyPolicy: "Terms of Use & Privacy Policy",
            pendingConfirmation: "Please click the confirmation link in your email.",
            thanks: "Thanks for being a subscriber!",
            unsubscribed: "Unsubscribed.",
            resubscribe: "Resubscribe"
        }
    },
    'en-US': {
        subscriber: {
            placeholder: "Enter your email",
            subscribe: "Subscribe",
            unsubscribe: "Unsubscribe",
            privacy: "By subscribing you agree to the",
            privacyPolicy: "Privacy Policy",
            pendingConfirmation: "Please click the confirmation link in your email.",
            thanks: "Thanks for being a subscriber!",
            unsubscribed: "Unsubscribed.",
            resubscribe: "Resubscribe"
        }
    },
    'zh': {
        subscriber: {
            placeholder: "输入你的电子邮件",
            subscribe: "订阅",
            unsubscribe: "取消订阅",
            privacy: "订阅即表示您同意",
            privacyPolicy: "隐私政策",
            pendingConfirmation: "请点击您电子邮件中的确认链接。",
            thanks: "感谢您的订阅！",
            unsubscribed: "已取消订阅。",
            resubscribe: "重新订阅"
        }
    },
    'zh-CN': {
        subscriber: {
            placeholder: "输入你的电子邮件",
            subscribe: "订阅",
            unsubscribe: "取消订阅",
            privacy: "订阅即表示您同意",
            privacyPolicy: "隐私政策",
            pendingConfirmation: "请点击您电子邮件中的确认链接。",
            thanks: "感谢您的订阅！",
            unsubscribed: "已取消订阅。",
            resubscribe: "重新订阅"
        }
    },
    'zh-TW': {
        subscriber: {
            placeholder: "輸入你的電子郵件",
            subscribe: "訂閱",
            unsubscribe: "取消訂閱",
            privacy: "訂閱即表示您同意",
            privacyPolicy: "隱私政策",
            pendingConfirmation: "請點擊您電子郵件中的確認鏈接。",
            thanks: "感謝您的訂閱！",
            unsubscribed: "已取消訂閱。",
            resubscribe: "重新訂閱"
        }
    },
    'hi': {
        subscriber: {
            placeholder: "अपना ईमेल दर्ज करें",
            subscribe: "सदस्यता लें",
            unsubscribe: "सदस्यता समाप्त करें",
            privacy: "सदस्यता लेने से आप सहमत होते हैं",
            privacyPolicy: "गोपनीयता नीति",
            pendingConfirmation: "कृपया अपने ईमेल में दिए गए पुष्टि लिंक पर क्लिक करें।",
            thanks: "सदस्यता लेने के लिए धन्यवाद!",
            unsubscribed: "सदस्यता समाप्त हो गई है।",
            resubscribe: "पुनः सदस्यता लें"
        }
    },
    'es': {
        subscriber: {
            placeholder: "Ingresa tu correo electrónico",
            subscribe: "Suscribirse",
            unsubscribe: "Darse de baja",
            privacy: "Al suscribirte aceptas la",
            privacyPolicy: "Política de Privacidad",
            pendingConfirmation: "Por favor, haz clic en el enlace de confirmación en tu correo electrónico.",
            thanks: "¡Gracias por suscribirte!",
            unsubscribed: "Te has dado de baja.",
            resubscribe: "Volver a suscribirse"
        }
    },
    'es-ES': {
        subscriber: {
            placeholder: "Ingresa tu correo electrónico",
            subscribe: "Suscribirse",
            unsubscribe: "Darse de baja",
            privacy: "Al suscribirte aceptas la",
            privacyPolicy: "Política de Privacidad",
            pendingConfirmation: "Por favor, haz clic en el enlace de confirmación en tu correo electrónico.",
            thanks: "¡Gracias por suscribirte!",
            unsubscribed: "Te has dado de baja.",
            resubscribe: "Volver a suscribirse"
        }
    },
    'es-MX': {
        subscriber: {
            placeholder: "Ingresa tu correo electrónico",
            subscribe: "Suscribirse",
            unsubscribe: "Darse de baja",
            privacy: "Al suscribirte aceptas la",
            privacyPolicy: "Política de Privacidad",
            pendingConfirmation: "Por favor, haz clic en el enlace de confirmación en tu correo electrónico.",
            thanks: "¡Gracias por suscribirte!",
            unsubscribed: "Te has dado de baja.",
            resubscribe: "Volver a suscribirse"
        }
    },
    'es-AR': {
        subscriber: {
            placeholder: "Ingresa tu correo electrónico",
            subscribe: "Suscribirse",
            unsubscribe: "Darse de baja",
            privacy: "Al suscribirte aceptas la",
            privacyPolicy: "Política de Privacidad",
            pendingConfirmation: "Por favor, haz clic en el enlace de confirmación en tu correo electrónico.",
            thanks: "¡Gracias por suscribirte!",
            unsubscribed: "Te has dado de baja.",
            resubscribe: "Volver a suscribirse"
        }
    },
    'fr': {
        subscriber: {
            placeholder: "Entrez votre e-mail",
            subscribe: "S'abonner",
            unsubscribe: "Se désabonner",
            privacy: "En vous abonnant, vous acceptez la",
            privacyPolicy: "Politique de Confidentialité",
            pendingConfirmation: "Veuillez cliquer sur le lien de confirmation dans votre e-mail.",
            thanks: "Merci d'être abonné(e) !",
            unsubscribed: "Désabonné(e).",
            resubscribe: "Se réabonner"
        }
    },
    'fr-FR': {
        subscriber: {
            placeholder: "Entrez votre e-mail",
            subscribe: "S'abonner",
            unsubscribe: "Se désabonner",
            privacy: "En vous abonnant, vous acceptez la",
            privacyPolicy: "Politique de Confidentialité",
            pendingConfirmation: "Veuillez cliquer sur le lien de confirmation dans votre e-mail.",
            thanks: "Merci d'être abonné(e) !",
            unsubscribed: "Désabonné(e).",
            resubscribe: "Se réabonner"
        }
    },
    'fr-CA': {
        subscriber: {
            placeholder: "Entrez votre e-mail",
            subscribe: "S'abonner",
            unsubscribe: "Se désabonner",
            privacy: "En vous abonnant, vous acceptez la",
            privacyPolicy: "Politique de Confidentialité",
            pendingConfirmation: "Veuillez cliquer sur le lien de confirmation dans votre e-mail.",
            thanks: "Merci d'être abonné(e) !",
            unsubscribed: "Désabonné(e).",
            resubscribe: "Se réabonner"
        }
    },
    'ar': {
        subscriber: {
            placeholder: "أدخل بريدك الإلكتروني",
            subscribe: "اشترك",
            unsubscribe: "إلغاء الاشتراك",
            privacy: "بالاشتراك ، فإنك توافق على",
            privacyPolicy: "سياسة الخصوصية",
            pendingConfirmation: "يرجى النقر على رابط التأكيد في بريدك الإلكتروني.",
            thanks: "شكرًا لكونك مشتركًا!",
            unsubscribed: "تم إلغاء الاشتراك.",
            resubscribe: "إعادة الاشتراك"
        }
    },
    'ar-SA': {
        subscriber: {
            placeholder: "أدخل بريدك الإلكتروني",
            subscribe: "اشترك",
            unsubscribe: "إلغاء الاشتراك",
            privacy: "بالاشتراك ، فإنك توافق على",
            privacyPolicy: "سياسة الخصوصية",
            pendingConfirmation: "يرجى النقر على رابط التأكيد في بريدك الإلكتروني.",
            thanks: "شكرًا لكونك مشتركًا!",
            unsubscribed: "تم إلغاء الاشتراك.",
            resubscribe: "إعادة الاشتراك"
        }
    },
    'ar-EG': {
        subscriber: {
            placeholder: "أدخل بريدك الإلكتروني",
            subscribe: "اشترك",
            unsubscribe: "إلغاء الاشتراك",
            privacy: "بالاشتراك ، فإنك توافق على",
            privacyPolicy: "سياسة الخصوصية",
            pendingConfirmation: "يرجى النقر على رابط التأكيد في بريدك الإلكتروني.",
            thanks: "شكرًا لكونك مشتركًا!",
            unsubscribed: "تم إلغاء الاشتراك.",
            resubscribe: "إعادة الاشتراك"
        }
    },
    'bn': {
        subscriber: {
            placeholder: "আপনার ইমেইল লিখুন",
            subscribe: "সাবস্ক্রাইব করুন",
            unsubscribe: "আনসাবস্ক্রাইব করুন",
            privacy: "সাবস্ক্রাইব করার মাধ্যমে আপনি সম্মত হচ্ছেন",
            privacyPolicy: "গোপনীয়তা নীতি",
            pendingConfirmation: "অনুগ্রহ করে আপনার ইমেইলে থাকা নিশ্চিতকরণ লিঙ্কে ক্লিক করুন।",
            thanks: "সাবস্ক্রাইবার হওয়ার জন্য ধন্যবাদ!",
            unsubscribed: "আনসাবস্ক্রাইব করা হয়েছে।",
            resubscribe: "পুনরায় সাবস্ক্রাইব করুন"
        }
    },
    'pt': {
        subscriber: {
            placeholder: "Digite seu e-mail",
            subscribe: "Inscrever-se",
            unsubscribe: "Cancelar inscrição",
            privacy: "Ao se inscrever, você concorda com a",
            privacyPolicy: "Política de Privacidade",
            pendingConfirmation: "Por favor, clique no link de confirmação no seu e-mail.",
            thanks: "Obrigado por ser um assinante!",
            unsubscribed: "Inscrição cancelada.",
            resubscribe: "Reinscrever-se"
        }
    },
    'pt-PT': {
        subscriber: {
            placeholder: "Digite seu e-mail",
            subscribe: "Inscrever-se",
            unsubscribe: "Cancelar inscrição",
            privacy: "Ao se inscrever, você concorda com a",
            privacyPolicy: "Política de Privacidade",
            pendingConfirmation: "Por favor, clique no link de confirmação no seu e-mail.",
            thanks: "Obrigado por ser um assinante!",
            unsubscribed: "Inscrição cancelada.",
            resubscribe: "Reinscrever-se"
        }
    },
    'pt-BR': {
        subscriber: {
            placeholder: "Digite seu e-mail",
            subscribe: "Inscrever-se",
            unsubscribe: "Cancelar inscrição",
            privacy: "Ao se inscrever, você concorda com a",
            privacyPolicy: "Política de Privacidade",
            pendingConfirmation: "Por favor, clique no link de confirmação no seu e-mail.",
            thanks: "Obrigado por ser um assinante!",
            unsubscribed: "Inscrição cancelada.",
            resubscribe: "Reinscrever-se"
        }
    },
    'ru': {
        subscriber: {
            placeholder: "Введите ваш email",
            subscribe: "Подписаться",
            unsubscribe: "Отписаться",
            privacy: "Подписываясь, вы соглашаетесь с",
            privacyPolicy: "Политикой конфиденциальности",
            pendingConfirmation: "Пожалуйста, нажмите на ссылку подтверждения в вашем электронном письме.",
            thanks: "Спасибо за подписку!",
            unsubscribed: "Вы отписались.",
            resubscribe: "Повторно подписаться"
        }
    },
    'ja': {
        subscriber: {
            placeholder: "メールアドレスを入力してください",
            subscribe: "登録",
            unsubscribe: "登録解除",
            privacy: "登録することで、あなたは",
            privacyPolicy: "プライバシーポリシー",
            pendingConfirmation: "メール内の確認リンクをクリックしてください。",
            thanks: "ご購読ありがとうございます！",
            unsubscribed: "登録解除されました。",
            resubscribe: "再登録する"
        }
    },
    'pa': {
        subscriber: {
            placeholder: "ਆਪਣਾ ਈਮੇਲ ਦਰਜ ਕਰੋ",
            subscribe: "ਸਬਸਕ੍ਰਾਈਬ ਕਰੋ",
            unsubscribe: "ਸਬਸਕ੍ਰਾਈਬ ਹਟਾਓ",
            privacy: "ਸਬਸਕ੍ਰਾਈਬ ਕਰਕੇ ਤੁਸੀਂ ਸਹਿਮਤ ਹੋ",
            privacyPolicy: "ਗੋਪਨੀਯਤਾ ਨੀਤੀ",
            pendingConfirmation: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਈਮੇਲ ਵਿੱਚ ਪੁਸ਼ਟੀ ਲਿੰਕ 'ਤੇ ਕਲਿੱਕ ਕਰੋ।",
            thanks: "ਸਬਸਕ੍ਰਾਈਬਰ ਬਣਨ ਲਈ ਧੰਨਵਾਦ!",
            unsubscribed: "ਸਬਸਕ੍ਰਾਈਬ ਹਟਾ ਦਿੱਤੀ ਗਈ ਹੈ।",
            resubscribe: "ਮੁੜ ਸਬਸਕ੍ਰਾਈਬ ਕਰੋ"
        }
    },
    'de': {
        subscriber: {
            placeholder: "Geben Sie Ihre E-Mail-Adresse ein",
            subscribe: "Abonnieren",
            unsubscribe: "Abmelden",
            privacy: "Durch die Anmeldung stimmen Sie zu",
            privacyPolicy: "Datenschutzrichtlinie",
            pendingConfirmation: "Bitte klicken Sie auf den Bestätigungslink in Ihrer E-Mail.",
            thanks: "Vielen Dank für Ihr Abonnement!",
            unsubscribed: "Abgemeldet.",
            resubscribe: "Erneut abonnieren"
        }
    },
    'de-DE': {
        subscriber: {
            placeholder: "Geben Sie Ihre E-Mail-Adresse ein",
            subscribe: "Abonnieren",
            unsubscribe: "Abmelden",
            privacy: "Durch die Anmeldung stimmen Sie zu",
            privacyPolicy: "Datenschutzrichtlinie",
            pendingConfirmation: "Bitte klicken Sie auf den Bestätigungslink in Ihrer E-Mail.",
            thanks: "Vielen Dank für Ihr Abonnement!",
            unsubscribed: "Abgemeldet.",
            resubscribe: "Erneut abonnieren"
        }
    },
    'it': {
        subscriber: {
            placeholder: "Inserisci la tua email",
            subscribe: "Iscriviti",
            unsubscribe: "Annulla iscrizione",
            privacy: "Iscrivendoti accetti la",
            privacyPolicy: "Politica sulla Privacy",
            pendingConfirmation: "Clicca sul link di conferma nella tua email.",
            thanks: "Grazie per esserti iscritto!",
            unsubscribed: "Disiscritto.",
            resubscribe: "Iscriviti di nuovo"
        }
    },
    'it-IT': {
        subscriber: {
            placeholder: "Inserisci la tua email",
            subscribe: "Iscriviti",
            unsubscribe: "Annulla iscrizione",
            privacy: "Iscrivendoti accetti la",
            privacyPolicy: "Politica sulla Privacy",
            pendingConfirmation: "Clicca sul link di conferma nella tua email.",
            thanks: "Grazie per esserti iscritto!",
            unsubscribed: "Disiscritto.",
            resubscribe: "Iscriviti di nuovo"
        }
    },
    'nl': {
        subscriber: {
            placeholder: "Vul je e-mailadres in",
            subscribe: "Abonneren",
            unsubscribe: "Afmelden",
            privacy: "Door u in te schrijven gaat u akkoord met de",
            privacyPolicy: "Privacybeleid",
            pendingConfirmation: "Klik op de bevestigingslink in uw e-mail.",
            thanks: "Bedankt voor uw abonnement!",
            unsubscribed: "Uitgeschreven.",
            resubscribe: "Opnieuw abonneren"
        }
    },
    'nl-NL': {
        subscriber: {
            placeholder: "Vul je e-mailadres in",
            subscribe: "Abonneren",
            unsubscribe: "Afmelden",
            privacy: "Door u in te schrijven gaat u akkoord met de",
            privacyPolicy: "Privacybeleid",
            pendingConfirmation: "Klik op de bevestigingslink in uw e-mail.",
            thanks: "Bedankt voor uw abonnement!",
            unsubscribed: "Uitgeschreven.",
            resubscribe: "Opnieuw abonneren"
        }
    },
    'sw': {
        subscriber: {
            placeholder: "Weka barua pepe yako",
            subscribe: "Jisajili",
            unsubscribe: "Ondoa usajili",
            privacy: "Kwa kujisajili unakubali",
            privacyPolicy: "Sera ya Faragha",
            pendingConfirmation: "Tafadhali bonyeza kiungo cha uthibitisho kwenye barua pepe yako.",
            thanks: "Asante kwa kuwa mteja!",
            unsubscribed: "Umeondolewa usajili.",
            resubscribe: "Jisajili tena"
        }
    },
    'yo': {
        subscriber: {
            placeholder: "Tẹ imeeli rẹ sii",
            subscribe: "Forukọsilẹ",
            unsubscribe: "Ṣe asopọ kuro",
            privacy: "Nipasẹ iforukọsilẹ o gba lati",
            privacyPolicy: "Ilana Aṣiri",
            pendingConfirmation: "Jọwọ tẹ ọna asopọ ijẹrisi ninu imeeli rẹ.",
            thanks: "O ṣeun fun jijẹ alabapin!",
            unsubscribed: "Ti o ti ṣe alabapin.",
            resubscribe: "Forukọsilẹ lẹẹkansi"
        }
    },
    'ig': {
        subscriber: {
            placeholder: "Tinye email gị",
            subscribe: "Debanye aha",
            unsubscribe: "Wepu aha",
            privacy: "Site n'ịdebanye aha ị kwenyere na",
            privacyPolicy: "Atụmatụ Nzuzo",
            pendingConfirmation: "Biko pịa njikọ nkwenye na email gị.",
            thanks: "Daalụ maka ịbụ onye debanyere aha!",
            unsubscribed: "Agbanyere aha.",
            resubscribe: "Debanye aha ọzọ"
        }
    },
    'ha': {
        subscriber: {
            placeholder: "Shigar da imel ɗin ku",
            subscribe: "Yi rijista",
            unsubscribe: "An cire rajista",
            privacy: "Ta hanyar biyan kuɗi kun yarda da",
            privacyPolicy: "Dokar Sirri",
            pendingConfirmation: "Da fatan za a danna mahaɗin tabbatarwa a cikin imel ɗin ku.",
            thanks: "Na gode da kasancewa mai biyan kuɗi!",
            unsubscribed: "An cire rajista.",
            resubscribe: "Yi rajista sake"
        }
    },
    'id': {
        subscriber: {
            placeholder: "Masukkan email Anda",
            subscribe: "Berlangganan",
            unsubscribe: "Berhenti berlangganan",
            privacy: "Dengan berlangganan, Anda setuju dengan",
            privacyPolicy: "Kebijakan Privasi",
            pendingConfirmation: "Silakan klik tautan konfirmasi di email Anda.",
            thanks: "Terima kasih telah berlangganan!",
            unsubscribed: "Berhenti berlangganan.",
            resubscribe: "Berlangganan lagi"
        }
    },
    'ms': {
        subscriber: {
            placeholder: "Masukkan e-mel anda",
            subscribe: "Langgan",
            unsubscribe: "Nyahlanggan",
            privacy: "Dengan melanggan anda bersetuju dengan",
            privacyPolicy: "Dasar Privasi",
            pendingConfirmation: "Sila klik pautan pengesahan dalam e-mel anda.",
            thanks: "Terima kasih kerana menjadi pelanggan!",
            unsubscribed: "Nyahlanggan.",
            resubscribe: "Langgan semula"
        }
    },
    'fil': {
        subscriber: {
            placeholder: "Ilagay ang iyong email",
            subscribe: "Mag-subscribe",
            unsubscribe: "Mag-unsubscribe",
            privacy: "Sa pag-subscribe, sumasang-ayon ka sa",
            privacyPolicy: "Patakaran sa Privacy",
            pendingConfirmation: "Pakiklik ang confirmation link sa iyong email.",
            thanks: "Salamat sa pagiging subscriber!",
            unsubscribed: "Naka-unsubscribe.",
            resubscribe: "Mag-subscribe muli"
        }
    },
    'tl': {
        subscriber: {
            placeholder: "Ilagay ang iyong email",
            subscribe: "Mag-subscribe",
            unsubscribe: "Mag-unsubscribe",
            privacy: "Sa pag-subscribe, sumasang-ayon ka sa",
            privacyPolicy: "Patakaran sa Privacy",
            pendingConfirmation: "Pakiklik ang confirmation link sa iyong email.",
            thanks: "Salamat sa pagiging subscriber!",
            unsubscribed: "Naka-unsubscribe.",
            resubscribe: "Mag-subscribe muli"
        }
    }
}
