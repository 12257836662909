<script setup>
import { onMounted, onUnmounted, ref, watch, computed, markRaw } from 'vue'

import { useChartStore } from '../../stores/chart.js'
const chart = useChartStore()

import RegionSelect from '@shared/components/RegionSelect.vue'
import LocaleSelect from '@shared/components/LocaleSelect.vue'
import Privacy from '@shared/components/Privacy.vue'
import ChartPreviewFrame from './ChartPreviewFrame.vue'
import WebPreview from './WebPreview.vue'


import { useLocationStore } from '@shared/stores/location.js';
const location = useLocationStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

import { useScheduleStore } from '../../stores/schedule.js'
const schedule = useScheduleStore()

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { ArrowUpOnSquareIcon, EnvelopeIcon, TruckIcon, ArrowDownCircleIcon, LanguageIcon, ChatBubbleLeftIcon, GlobeEuropeAfricaIcon, MapPinIcon, LinkIcon, XCircleIcon, ChevronDownIcon } from '@heroicons/vue/24/outline'
import { IconArrowsDiff, IconShirt, IconUsers, IconWorld } from '@tabler/icons-vue'



const langSelectIsOpen = ref(false)
const langSelectRef = ref(null)

const toggleLangSelect = () => {
    langSelectIsOpen.value = !langSelectIsOpen.value
}

const handleClickOutside = (event) => {
    if (langSelectRef.value && !langSelectRef.value.contains(event.target)) {
        langSelectIsOpen.value = false;
    }
}


onMounted(() => {
    schedule.localiseGameSchedule(),
        document.addEventListener("click", handleClickOutside)
})

onUnmounted(() => {
    document.addEventListener("click", handleClickOutside)
})

const makerNameMaxLen = 35

const makerNameUsed = computed(() => {
    if (chart.makerName) {
        return chart.makerName.length

    } else {
        return 0
    }
})

const makerMessageMaxLen = 80

const makerMessageUsed = computed(() => {
    if (chart.makerMessage) {
        return chart.makerMessage.length

    } else {
        return 0
    }
})

</script>


<template>
    <div class="w-full  flex flex-col justify-start  items-center ">

        <div class="flex flex-col w-full space-y-2 items-center justify-center mt-4">
            <div id="chart-create-form" class=" flex flex-col w-full items-center justify-center space-y-2">

                <div id="timezone-group" class="w-full flex items-center justify-center space-x-2">

                        <!-- <GlobeEuropeAfricaIcon class="w-7 sm:w-8 sm:mr-2 text-sky-700" /> -->

                        <RegionSelect class=""/>

                        <LocaleSelect class=""/>
                </div>



                <div ref="langSelectRef" class="relative w-full">
                    <button aria-label="site language select" @click="langSelectIsOpen=false" v-if="langSelectIsOpen" class="fixed top-0 h-full w-full z-10"></button>
                    <button aria-label="site language select" @click="langSelectIsOpen = true" id="chart-lang-select"
                    class="shadow-md w-full flex items-center justify-evenly space-x-2 h-10 sm:mr-0 border pl-2 pr-3 border-sky-800 bg-white rounded-md  focus:outline-none text-base">
                    <LanguageIcon class="w-5 min-w-5 sm:w-8 sm:mr-1 text-sky-700" />
                    <div class="text-sky-800 font-semibold">{{ language.supportedLanguages.find(l => l.code === chart.chartLang)?.name || 'Select Language'  }}</div>
                    <div
                            class="w-full relative h-8 font-semibold text-sky-800 sm:text-sm bg-white block  appearance-none   focus:outline-none focus:ring-0">
                            <div v-if="langSelectIsOpen" class="absolute z-20 -top-24 bg-white w-48 space-y-1.5 flex flex-col rounded-md border border-sky-800 shadow-lg p-1">
                                <button aria-label="site language select" v-for="supportedLanguage in language.supportedLanguages"
                                    :key="supportedLanguage.code" :value="supportedLanguage.code"
                                    class="hover:bg-sky-800 hover:text-white w-full p-1 text-left"
                                    @click="toggleLangSelect(); console.log(supportedLanguage.code); chart.chartLang = supportedLanguage.code">

                                    {{ supportedLanguage.name }}
                                </button>

                            </div>

                        </div>
                        <ChevronDownIcon class="h-4 text-sky-800" />
                    </button>
                </div>





                <div class="w-full flex flex-col items-center justify-start space-y-2" id="chart-entry-holder">


                    <div id="maker-name-frame" class="flex flex-col w-full items-center justify-start">
                        <div class="w-full pl-2 text-base text-sky-800 font-medium flex items-baseline justify-start">
                            <!-- <div>

                                Who is this chart for?
                            </div> -->
                            <!-- <div class="text-xs opacity-45 ml-2">Optional</div> -->
                        </div>
                        <div id="maker-name-form"
                            class="shadow-md  w-full flex items-center justify-evenly h-12 sm:mr-0 border pl-2 pr-3 border-sky-800 bg-white rounded-md  focus:outline-none text-base text-sky-800">

                            <IconUsers class="w-6 sm:w-8 min-w-6  sm:mr-2 text-sky-700" />
                            <input v-model="chart.makerName" placeholder="Your club/class/organisation etc"
                                :maxlength="makerNameMaxLen"
                                class="ml-2 placeholder:text-sky-700 placeholder:font-light placeholder:text-base w-full focus:outline-none ">
                            </input>
                            <!-- <button v-if="chart.makerName" @click="chart.makerName = null"
                                class=" flex items-center justify-end min-w-10 h-full">

                                <XCircleIcon class=" h-5 w-5 text-sky-800" />
                            </button> -->
                            <div class="ml-2 min-w-10 text-sm flex items-center justify-end opacity-65">

                                {{ makerNameUsed }}/{{ makerNameMaxLen }}

                            </div>

                        </div>
                        <div class="w-full flex items-end justify-end pr-2 opacity-75 text-sky-800">



                        </div>
                    </div>

                    <div id="maker-message-frame" class="flex flex-col w-full items-center justify-start">
                        <!-- <div class="w-full pl-2 text-base text-sky-800 font-medium flex items-baseline justify-start">
                            <div>

                                What do you want to say?
                            </div>
                            <div class="text-xs opacity-45 ml-2">Optional</div>
                        </div> -->

                        <div id="maker-message"
                            class="shadow-md  w-full flex items-center justify-evenly  h-12 sm:mr-0 border pl-2 pr-3 border-sky-800 bg-white rounded-md focus:outline-none text-base text-sky-800">
                            <ChatBubbleLeftIcon class="w-6 min-w-6  sm:w-8 sm:mr-2 text-sky-700" />
                            <input v-model="chart.makerMessage" placeholder="Your message"
                                :maxlength="makerMessageMaxLen"
                                class="ml-2 placeholder:text-sky-700 placeholder:font-light  placeholder:text-base w-full focus:outline-none pr-5">
                            </input>
                            <!-- <button v-if="chart.makerMessage" @click="chart.makerMessage = null"
                                class=" flex items-center justify-end min-w-10 h-full ml-2">

                                <XCircleIcon class=" h-5 w-5 text-sky-800" />
                            </button> -->
                            <div class="ml-2 min-w-10 text-sm flex items-center justify-end opacity-65">

                                {{ makerMessageUsed }}/{{ makerMessageMaxLen }}
                            </div>

                        </div>
                    </div>

                    <div id="maker-name-frame" class="flex flex-col w-full items-center justify-start">
                        <!-- <div class="w-full pl-2 text-base text-sky-800 font-medium flex items-baseline justify-start">
                            <div>

                                Your website or link
                            </div>
                            <div class="text-xs opacity-45 ml-2">Optional</div>
                        </div> -->
                        <div id="maker-url"
                            class="shadow-md w-full flex items-center justify-evenly  h-12 sm:mr-0 border pl-2  border-sky-800 bg-white rounded-md focus:outline-none text-base text-sky-800">
                            <LinkIcon class="w-6 min-w-6 sm:w-8 sm:mr-2 text-sky-700 " />
                            <div class="opacity-45 text-sm ml-2">https://</div>
                            <input v-model="chart.makerUrl" @input="chart.makerUrl = $event.target.value.toLowerCase()"
                                placeholder="your-website.com" type="text" inputmode="text" autocapitalize="none"
                                class="placeholder:text-sky-700 placeholder:font-light placeholder:opacity-65 placeholder:text-base w-full focus:outline-none lowercase">
                            </input>
                            <button aria-label="clear url form" v-if="chart.makerUrl" @click="chart.makerUrl = null"
                                class=" flex items-center justify-end w-14 h-full pr-2">

                                <XCircleIcon class=" h-5 w-5 text-sky-800" />
                            </button>
                            <button aria-label="check url" @click="chart.fetchSiteMetadata()" v-if="chart.makerUrl"
                                class="bg-sky-800 text-white text-xs w-16 h-full rounded-r-md px-1">Check</button>
                        </div>
                        <div class="px-1 sm:px-4 w-full">

                            <WebPreview v-if="chart.checkInProgress" class="w-full" />
                        </div>
                    </div>

                </div>


            </div>
            <button aria-label="preview your chart" @click="ui.openRightTray(markRaw(ChartPreviewFrame))"
                class="!mt-4 h-14 shadow-lg font-medium w-full rounded-md bg-white text-sky-800  border border-sky-800 ">
                Preview
            </button>

            <button aria-label="create your chart" @click="chart.createChart"
                class="shadow-lg font-medium text-lg bg-sky-800 text-white h-14 w-full rounded-md border border-sky-800">
                Create
            </button>
            <div class="text-xxs text-sky-800 mt-1 pl-2 w-full flex  sm:flex-row items-start  justify-start">
                <div>By using this service you agree to the</div>
                <button aria-label="view the terms and privacy policy" class="underline ml-1 text-left" @click="ui.openRightTray(markRaw(Privacy))">Terms of Use and
                    Privacy Policy</button>
            </div>
        </div>
    </div>
</template>
