<template>

    <div id="three-dots" class="flex space-x-1">

        <div class=" h-3 w-3 bg-sky-800 opacity-70 rounded-full animate-pulse " style="animation-delay: 0s">
        </div>
        <div class="  h-3 w-3 bg-sky-800 opacity-70 rounded-full animate-pulse " style="animation-delay: 0.2s">
        </div>
        <div class=" top-3.5 h-3 w-3 bg-sky-800 opacity-70 rounded-full animate-pulse " style="animation-delay: 0.4s">
        </div>
    </div>
</template>

<script setup>

</script>