<script setup>

import {onMounted} from 'vue'

import EmailSubscriber from '@shared/components/EmailSubscriber.vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

const props = defineProps({
    competition: String,
    location: String,
    date: String,
    timezone: String
})

onMounted(() => {
    ui.scrollToTop()
})

</script>

<template>
    <div class="w-full flex flex-col items-center justify-start mt-16 text-sky-800 ">
        <div class="w-full flex flex-col space-y-3 items-center justify-center py-6 bg-sky-800 text-white ">
            <div class="text-2xl w-full text-center font-bold px-4 max-w-2xl">{{ props.competition }}</div>
            <div class="w-full text-lg px-4 max-w-2xl">Location: {{ props.location }}</div>
            <div class="w-full text-lg px-4 max-w-2xl">Date: {{ props.date }}</div>
            <div class="w-full text-lg px-4 max-w-2xl">Timezone: {{ props.timezone }}</div>
        </div>
        <div class="w-full text-xl mt-14 font-bold px-4 max-w-2xl">Keep up to date:</div>
        <EmailSubscriber class="px-4 mt-4 max-w-2xl"/>
    </div>
</template>