import { ref, watch, nextTick } from "vue"
import { defineStore } from "pinia"
import chartApi from '../api/chartApi.js'
import { apiError } from '@shared/api/apiError.js'
import sharedApi from '@shared/api/sharedApi.js'
import { useUIStore } from '@shared/stores/ui.js'
import { useLocationStore } from '@shared/stores/location.js'
import { useLanguageStore } from '@shared/stores/language.js'
import { useChartRefsStore } from './chartRefs.js'
import QRCode from 'qrcode'




export const useChartStore = defineStore('chartStore', () => {

    // CURRENT

    const ui = useUIStore()
    const language = useLanguageStore()
    const location = useLocationStore()
    const chartRefs = useChartRefsStore()

    const chartId = ref(null) // essential for knowing if a chart is in state or not
    const storageUrl = ref(null)
    const chartLang = ref(null)

    const chartGetInProgress = ref(false)

    // responses only?
    const chartTz = ref(null)

    const makerName = ref(null)
    const makerMessage = ref(null)


    async function getChartLanguage() {
        const chartLangPref = localStorage.getItem("chartLangPref")


        if (chartLangPref) {
            console.info("%cYour chart language preference was already set, using that :)", "color: green; font-weight: normal;")
            if (language.supportedLanguages.some(lang => lang.code === chartLangPref)) {
                chartLang.value = chartLangPref
            } else {
                chartLang.value = language.uiLang || 'en'
            }
        } else {
            chartLang.value = language.uiLang || 'en'
        }
    }

    function changeChartLang(event) {
        // change state
        chartLang.value = event.target.value
        // set localStorage pref
        localStorage.setItem('chartLangPref', chartLang.value)
    }

    watch(() => language.uiLang, (newUiLang, oldUiLang) => {
        const chartLangPref = localStorage.getItem("chartLangPref")
        if (!chartLangPref) {
            chartLang.value = newUiLang
        }
    })


    function storeChart(newChart) {
        const chartHistoryKey = 'chartHistory' // Define the key

        // Check if array exists in localStorage
        const chartHistory = localStorage.getItem(chartHistoryKey)

        let chartHistoryArray = []

        if (chartHistory) {

            chartHistoryArray = JSON.parse(chartHistory)
        }

        chartHistoryArray.push(newChart)
        localStorage.setItem(chartHistoryKey, JSON.stringify(chartHistoryArray))
    }

    function setChartState(state) {
        chartId.value = state.chartId
        chartLang.value = state.chartLanguage
        chartTz.value = state.chartTimezone
        storageUrl.value = state.storageUrl
        makerMessage.value = state.makerMessage
        makerName.value = state.makerName
        makerUrl.value = state.makerUrl
    }

    function clearChartState() {
        chartId.value = null
        chartTz.value = null
        // chartList.value = null
        storageUrl.value = null
        makerMessage.value = null
        makerName.value = null
        makerUrl.value = null
    }

    async function createChart() {
        try {
            await nextTick()
            chartLoading.value = true
            const resp = await chartApi.postCreateChart(await createRequestPayload())

            console.log(resp.data)
            // set state
            setChartState(resp.data)

            // persist to LS
            storeChart(resp.data)


        } catch (error) {
            apiError(error, ui) // TODO revisit this, familiaise
        } finally {
            chartLoading.value = false
        }
    }

    async function createRequestPayload() {
        await nextTick()
        const bracketHTML = chartRefs.bracket?.outerHTML || ''
        const scheduleHTML = chartRefs.schedule?.outerHTML || 'here'

        const payload = {
            'bracketHTML': bracketHTML,
            'scheduleHTML': scheduleHTML,
            'chartTimezone': location.selectedTimezone,
            'chartLanguage': chartLang.value,
            'chartId': newChartId.value,
            'makerMessage': makerMessage.value,
            'makerUrl': makerUrl.value,
            'makerName': makerName.value
        }

        return payload
    }


    async function getChart(chartId) {
        try {
            const resp = await chartApi.getChart(chartId)

            console.log(resp.data.data)
            // set state
            setChartState(resp.data.data)

            // persist to LS
            storeChart(resp.data.data)


        } catch (error) {
            apiError(error, ui) // TODO revisit this, familiaise
        }
    }

    const chartList = ref(null)

    function getAllCharts() {
        const chartListLocalStorage = JSON.parse(localStorage.getItem('chartHistory')) || null

        if (chartListLocalStorage) {

            chartList.value = chartListLocalStorage.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        }
    }

    function findChart(chartId) {
        const chartListLocalStorage = JSON.parse(localStorage.getItem('chartHistory')) || null
        if (chartListLocalStorage) {

            const chart = chartListLocalStorage.find((item) => item.chartId === chartId)

            return chart
        }
    }

    function deleteChart(chartIdToRemove) {

        const trimmedChartList = chartList.value.filter((item) => item.chartId !== chartIdToRemove)
        chartList.value = trimmedChartList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        if (chartIdToRemove === chartId.value) {
            clearChartState()
        }

        if (chartList.value) {
            if (chartList.value.length === 0) {
                // clearChartState()
                deleteAll()
            } else {
                // clearChartState()

                localStorage.setItem('chartHistory', JSON.stringify(trimmedChartList))
            }
        }

    }

    function deleteAll() {
        localStorage.removeItem('chartHistory')
        clearChartState()
        chartList.value = null

    }

    const chartLoading = ref(false)

    const appUrl = import.meta.env.VITE_APP_NAME_URL

    const charityUrl = ref(`https://${appUrl}/euro2025/qr`)
    const charityQR = ref('')
    const generateCharityQRCode = async (url) => {
        try {
            charityQR.value = await QRCode.toString(url, { type: 'svg', margin: 1 })
        } catch (err) {
            console.error('Error generating QR code:', err)
        }
    }

    watch(() => charityUrl.value, (newUrl) => {
        if (newUrl) {
            generateCharityQRCode(newUrl)
        }
    }, { immediate: true })


    const checkInProgress = ref(false)

    const makerUrl = ref('')
    const makerQR = ref(null)

    watch(() => makerUrl.value, (newUrl) => {
        if (!newUrl) {
            checkInProgress.value = false
        }
    }, { immediate: true })





    const generateMakerQRCode = async (url) => {
        if (!url) {
            makerQR.value = null // Clear QR when URL is removed
            return
        }

        try {
            makerQR.value = await QRCode.toString(url, { type: 'svg', margin: 1 })
        } catch (err) {
            console.error('Error generating QR code:', err)
        }
    }

    watch(makerUrl, (newUrl) => {
        generateMakerQRCode(newUrl)
    }, { immediate: true })



    const shareQR = ref(null)

    const newChartId = ref(null)

    function generateGitStyleHash(len = 8) {
        const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < len; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    }

    const generateShareQRCode = async () => {

        try {
            shareQR.value = await QRCode.toString(`https://${appUrl}/euro2025/${newChartId.value}`, { type: 'svg', margin: 1 })
        } catch (err) {
            console.error('Error generating QR code:', err)
        }
    }

    watch(chartId, () => {
        newChartId.value = generateGitStyleHash()
        generateShareQRCode()

    }, { immediate: true })




    const siteTitle = ref('')
    const siteDescription = ref('')
    const isFetching = ref(false)
    const isValidUrl = ref(true)

    const fetchSiteMetadata = async () => {

        const urlToCheck = `https://${makerUrl.value}`

        isFetching.value = true
        isValidUrl.value = true
        checkInProgress.value = true

        try {
            const resp = await sharedApi.getMetadata(urlToCheck)

            siteTitle.value = resp.data.data.title
            siteDescription.value = resp.data.data.description

        } catch (err) {
            console.log(err)
            isValidUrl.value = false
            siteTitle.value = ''
            siteDescription.value = ''
        } finally {
            isFetching.value = false
        }
    }

    const messageLine1 = ref("")
    const messageLine2 = ref("")
    const messageLine3 = ref("")

    function splitMakerMessage() {
        const maxCharsPerLine = 30 // Adjust to fit your SVG
        if (makerMessage.value) {

            const words = makerMessage.value.split(" ")
            let currentLine = ""
            let lines = []

            words.forEach(word => {
                if ((currentLine + word).length > maxCharsPerLine) {
                    lines.push(currentLine.trim())
                    currentLine = word + " "
                } else {
                    currentLine += word + " "
                }
            })

            if (currentLine) lines.push(currentLine.trim())

            // Assign to state, fill missing lines with empty strings
            messageLine1.value = lines[0] || ""
            messageLine2.value = lines[1] || ""
            messageLine3.value = lines[2] || ""
        }
        else {
            messageLine1.value = ""
            messageLine2.value = ""
            messageLine3.value = ""
        }
    }

    watch(makerMessage, (newVal) => {
        splitMakerMessage(newVal)
    })


    const nameLine1 = ref("")
    const nameLine2 = ref("")

    function splitMakerName() {
        const maxCharsPerLine = 20 // Adjust to fit your SVG
        if (makerName.value) {

            const words = makerName.value.split(" ")
            let currentLine = ""
            let lines = []

            words.forEach(word => {
                if ((currentLine + word).length > maxCharsPerLine) {
                    lines.push(currentLine.trim())
                    currentLine = word + " "
                } else {
                    currentLine += word + " "
                }
            })

            if (currentLine) lines.push(currentLine.trim())

            // Assign to state, fill missing lines with empty strings
            nameLine1.value = lines[0] || ""
            nameLine2.value = lines[1] || ""

        }
        else {
            nameLine1.value = ""
            nameLine2.value = ""
        }
    }

    watch(makerName, (newVal) => {
        splitMakerName(newVal)
    })


    return {
        storageUrl,
        createChart,
        createRequestPayload,
        chartId,
        makerMessage,
        makerName,
        chartLang,
        getChartLanguage,
        changeChartLang,
        makerUrl,
        setChartState,
        clearChartState,
        getChart,
        chartList,
        getAllCharts,
        deleteChart,
        deleteAll,
        findChart,
        chartLoading,
        charityUrl,
        charityQR,
        makerQR,
        siteTitle,
        siteDescription,
        isFetching,
        isValidUrl,
        fetchSiteMetadata,
        checkInProgress,
        messageLine1,
        messageLine2,
        messageLine3,
        shareQR,
        generateShareQRCode,
        generateGitStyleHash,
        chartGetInProgress,
        nameLine1,
        nameLine2

    }

})