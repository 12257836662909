<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue'
import { useLocationStore } from '../stores/location.js'
const location = useLocationStore()

import { ArrowUpOnSquareIcon, EnvelopeIcon, TruckIcon, ArrowDownCircleIcon, LanguageIcon, ChatBubbleLeftIcon, GlobeEuropeAfricaIcon, MapPinIcon, LinkIcon, XCircleIcon, ChevronDownIcon } from '@heroicons/vue/24/outline'
import ThreeDots from './ThreeDots.vue'

const localeSelectIsOpen = ref(false)
const localeSelectRef = ref(null)
const localeListRef = ref(null)
const selectedOptionRef = ref(null)

const toggleLocaleSelect = async () => {
    localeSelectIsOpen.value = !localeSelectIsOpen.value

    if (localeSelectIsOpen.value) {
        // Wait for the dropdown to be rendered
        await nextTick()

        // Find the index of the selected timezone
        const selectedIndex = location.filteredTimezones.findIndex(
            tz => tz.zone === location.selectedTimezone
        )

        if (selectedIndex >= 0 && localeListRef.value) {
            // Calculate approximate scroll position based on item height
            const itemHeight = 36 // height of each item including padding
            const containerHeight = localeListRef.value.clientHeight
            const scrollPosition = (itemHeight * selectedIndex) - (containerHeight / 2) + (itemHeight / 2)

            // Set scroll position immediately
            localeListRef.value.scrollTop = Math.max(0, scrollPosition)
        }
    }
}

const setSelectedOptionRef = (el, tz) => {
    if (el && tz.zone === location.selectedTimezone) {
        selectedOptionRef.value = el
    }
}

const handleClickOutside = (event) => {
    if (localeSelectRef.value && !localeSelectRef.value.contains(event.target)) {
        localeSelectIsOpen.value = false
    }
}

onMounted(() => {
    document.addEventListener("click", handleClickOutside)
})

onUnmounted(() => {
    document.removeEventListener("click", handleClickOutside)
})
</script>

<template>
    <div ref="localeSelectRef" class="relative w-1/2 h-10">
        <button @click="localeSelectIsOpen = false" v-if="localeSelectIsOpen"
            class="fixed top-0 h-full w-full z-10"></button>

        <button @click="toggleLocaleSelect" id="chart-lang-select"
            class="shadow-md w-full flex items-center justify-evenly space-x-2 h-10 sm:mr-0 border pl-2 pr-3 border-sky-800 bg-white rounded-md focus:outline-none text-base">
            <MapPinIcon class="w-5 min-w-5 sm:w-8 sm:mr-1 text-sky-700" />
            <div v-if="location.selectedTimezone"
                class="text-sky-800 text-nowrap font-semibold overflow-hidden text-left w-full">
                {{ location.supportedTimezones.find(tz => tz.zone === location.selectedTimezone)?.locale || '' }}
            </div>
            <ThreeDots v-if="!location.selectedTimezone" class="w-full" />
            <ChevronDownIcon class="h-4 text-sky-800" />
        </button>

        <div v-if="localeSelectIsOpen" ref="localeListRef"
            class="absolute z-20 text-sky-800 -top-24 right-20 bg-white w-48 max-h-96 overflow-y-scroll space-y-1.5 flex flex-col rounded-md border border-sky-800 shadow-lg">
            <button v-for="tz in location.filteredTimezones" :key="tz.zone" :value="tz.zone"
                ref="(el) => setSelectedOptionRef(el, tz)"
                class="hover:bg-sky-800 hover:text-white text-wrap w-full pl-2 py-1 text-left"
                :class="location.selectedTimezone === tz.zone ? 'text-white font-bold bg-sky-800' : ''"
                @click="toggleLocaleSelect(); location.selectedTimezone = tz.zone">
                {{ tz.locale }}
            </button>
        </div>
    </div>
</template>