// import "./style.css";

// import { createApp } from "vue";
// import { createPinia } from "pinia";

// import App from "./App.vue";
// import router from "./router";

// const app = createApp(App);

// app.use(createPinia());
// app.use(router);

// app.mount("#app");

import "./style.css";

import { ViteSSG } from "vite-ssg";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";

export const createApp = ViteSSG(
  App,
  { routes: router.options.routes }, // Use existing Vue Router
  ({ app }) => {
    app.use(createPinia());
  },
  {
    formattedRoutes: [
      "/",        // Home page (SSG)
      "/about",   // About page (SSG)
      "/msf",
      "/privacy-terms",
      "/faq",
      "/afc2025",
      "/wc2026",
      "/ac2027",
      "/wc2027"   // Only blog pages get pre-rendered
    ]
  }
)
