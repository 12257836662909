<template>
  <div class="p-3 w-full flex flex-col items-start justify-center text-sky-800">
    <h1 class="text-3xl font-bold mb-4">Terms of Use & Privacy Policy</h1>
    <p class="mb-4"><strong>Effective Date:</strong> October 2024</p>

    <p class="mb-4">Here at {{ appName }}, your privacy is extremely important to me.</p>

    <p class="mb-4">
      I believe in a free, private, and open web. I do not use hidden data collection, cookies, identifiable tracking, advertisements, or unnecessary account creation or data capture in any of my web products. This document outlines both my privacy policy and the terms of use for this website.
    </p>

    <h2 class="text-2xl font-semibold mt-6 mb-2">Privacy Policy</h2>

    <h3 class="text-xl font-semibold mt-4 mb-2">Information I Collect</h3>
    <ul class="list-disc list-inside mb-4">
      <li><strong>IP Addresses:</strong> I do not store or collect IP addresses. However, I use a third-party service (<a href="https://ipinfo.io/" class="text-blue-500 underline" target="_blank">ipinfo.io</a>) to determine your country and timezone. This is used solely for personalizing your chart (e.g., timezone and language detection).</li>
      <li><strong>Route Information:</strong> I track the routes (URLs) your device visits on my website to understand usage patterns and improve the product.</li>
      <li><strong>Device Type:</strong> I record whether you visit from a desktop or mobile.</li>
      <li><strong>User Actions:</strong> I record interactions such as chart creation and downloads.</li>
      <li><strong>Non-Personal Data Only:</strong> None of the above are tied to any personally identifiable data, as I do not collect such information.</li>
    </ul>

    <h3 class="text-xl font-semibold mt-4 mb-2">What I Do NOT Collect</h3>
    <ul class="list-disc list-inside mb-4">
      <li><strong>No IP Logging:</strong> I do not log IP addresses.</li>
      <li><strong>No Cross-Visit Tracking:</strong> I do not track users across visits.</li>
      <li><strong>No Personal Data:</strong> I do not collect your name, address, or any identifiable information.</li>
      <li><strong>No Cookies or Trackers:</strong> I do not install cookies or tracking technologies on your device.</li>
      <li><strong>No Device Fingerprinting:</strong> I do not record any identifiable information about your device.</li>
    </ul>

    <h3 class="text-xl font-semibold mt-4 mb-2">Third-Party Services</h3>
    <p class="mb-4">
      While I do not log IP addresses, I rely on certain third-party services to run this website. These services may process or temporarily log your IP address as part of their normal operations:
    </p>
    <ul class="list-disc list-inside mb-4">
      <li><a href="https://ipinfo.io/privacy" class="text-blue-500 underline" target="_blank">ipinfo.io</a> (for country and timezone lookup)</li>
      <li><a href="https://rollbar.com/privacy/" class="text-blue-500 underline" target="_blank">Rollbar</a> (for error tracking and debugging)</li>
      <li><a href="https://vercel.com/legal/privacy-policy" class="text-blue-500 underline" target="_blank">Vercel</a> (for website hosting)</li>
      <li><a href="https://render.com/legal/privacy-policy" class="text-blue-500 underline" target="_blank">Render</a> (for backend infrastructure)</li>
    </ul>
    <p class="mb-4">
      I do not control how these services handle your data, so please refer to their privacy policies for details.
    </p>

    <h3 class="text-xl font-semibold mt-4 mb-2">Use of Collected Information</h3>
    <ul class="list-disc list-inside mb-4">
      <li><strong>Core Site Functionality:</strong> Ensuring proper timezone and language detection.</li>
      <li><strong>Product Improvement:</strong> Analyzing website traffic to understand user patterns and improve the experience.</li>
    </ul>

    <h3 class="text-xl font-semibold mt-4 mb-2">Data Storage</h3>
    <p class="mb-4">
      If you supply your email address for updates, it will be stored securely. Given the infrequent nature of major tournaments, I will keep your email for four years. You can unsubscribe at any time.
    </p>

    <p class="mb-4">
      <strong>Print Orders:</strong> If I offer a print order service, I will collect and store personal data (such as name, address, and email) only for the purpose of processing your order. This data will:
    </p>
    <ul class="list-disc list-inside mb-4">
      <li>Be used exclusively to fulfill and communicate about your order.</li>
      <li>Never be sold, shared, or used for marketing purposes.</li>
      <li>Be securely deleted after the order is processed and any necessary retention period for legal/accounting purposes expires.</li>
    </ul>

    <h2 class="text-2xl font-semibold mt-6 mb-2">Terms of Use</h2>

    <h3 class="text-xl font-semibold mt-4 mb-2">Accuracy of Information</h3>
    <p class="mb-4">
      I make every effort to ensure that the dates, times, and language translations on this website are correct. However, I cannot guarantee their accuracy. Users should independently verify any critical information.
    </p>

    <h3 class="text-xl font-semibold mt-4 mb-2">No Liability</h3>
    <p class="mb-4">
      I am not responsible for any errors, omissions, or inaccuracies in the information provided. I am also not liable for any decisions made based on the content of this website.
    </p>

    <h3 class="text-xl font-semibold mt-4 mb-2">Usage Restrictions</h3>
    <ul class="list-disc list-inside mb-4">
      <li>You may not use this website for illegal purposes.</li>
      <li>You may not attempt to access, alter, or interfere with the website’s code, functionality, or data collection processes.</li>
      <li>I reserve the right to restrict access to users who violate these terms.</li>
    </ul>

    <h3 class="text-xl font-semibold mt-4 mb-2">Accessibility</h3>
    <p class="mb-4">
      I strive to make this website accessible to all users, regardless of ability. If you experience any accessibility issues, please let me know.
    </p>

    <h3 class="text-xl font-semibold mt-4 mb-2">Reporting Errors</h3>
    <p class="mb-4">
      If you spot an error or incorrect information, please report it to <a href="mailto:ben@wallcharts.org" class="text-blue-500 underline">ben@wallcharts.org</a>.
    </p>

    <h2 class="text-2xl font-semibold mt-6 mb-2">Contact Me</h2>
    <p>By using this website, you agree to these terms and this privacy policy.</p>
  </div>
</template>

<script setup>
const appName = import.meta.env.VITE_APP_NAME;
</script>

