<script setup>
import { onMounted, ref, watch, computed } from 'vue'

import { useChartStore } from '../../stores/chart.js'
const chart = useChartStore()

import { useLocationStore } from '@shared/stores/location.js';
const location = useLocationStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

import { useScheduleStore } from '../../stores/schedule.js'
const schedule = useScheduleStore()

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { ArrowPathIcon, CheckCircleIcon, DocumentDuplicateIcon } from '@heroicons/vue/24/solid'
import { ArrowUpOnSquareIcon, EnvelopeIcon, TruckIcon, ArrowDownCircleIcon, LanguageIcon, ChatBubbleLeftIcon } from '@heroicons/vue/24/outline'



// current

const copied = ref(false)

const appUrl = import.meta.env.VITE_APP_NAME_URL

const copyUrl = async () => {
    try {

        copied.value = true
        await navigator.clipboard.writeText(`https://${appUrl}/euro2025/${chart.chartId}`)

        setTimeout(() =>
            copied.value = false, 10000
        )
    } catch (err) {
        console.error(err)
    }
}

async function shareNative() {
    if (navigator.share) {

        await navigator.share({
            title: `Here's your customised, print-at-home Women's Euro 2025 Wallchart supporting Médecins Sans Frontières, in your timezone and language.`,
            text: `Here's your customised, print-at-home Women's Euro 2025 Wallchart supporting Médecins Sans Frontières, in your timezone and language. \n\nGet it here:`,
            url: `/euro2025/${chart.chartId}`
        })

    } else {
        sendEmail()

    }
}

function sendEmail() {

    const recipient = null
    const subject = encodeURIComponent(`Here's your customised, print-at-home Women's Euro 2025 Wallchart supporting Médecins Sans Frontières, in your timezone and language.`)
    const body = encodeURIComponent(`https://${appUrl}/euro2025/${chart.chartId}`)

    const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`

    window.open(mailtoLink, '_blank')
}



onMounted(() => {
    schedule.localiseGameSchedule()
})







</script>

<template>
    <div class="w-full  flex flex-col justify-start  items-center space-y-2 mt-4">


        <div class="w-full flex flex-col justify-center items-center space-y-2 transition ease-in-out duration-1000">

            <a :href="chart.storageUrl" target="_blank"
                class="h-14 py-2 w-full text-white shadow-md rounded-md  bg-sky-800 flex justify-center items-center">

                <div class="w-full flex items-center text-lg justify-center">
                    <div class="pl-3">

                        Your PDF to print
                    </div>
                    <div class="w-10 flex items-center justify-center ">

                        <ArrowDownCircleIcon class="text-white w-6" />
                    </div>
                </div>
            </a>

            <button aria-label="share this chart" @click="shareNative()"
                class="h-14 py-2 w-full text-sky-800 border border-sky-800 rounded-md  bg-white flex justify-center items-center">
                <div class="w-full flex items-center justify-center">
                    <div class="pl-3">
                        Share this chart
                    </div>
                    <div class="w-10 flex items-center justify-center ">

                        <ArrowUpOnSquareIcon class="text-sky-800 w-6" />
                    </div>
                </div>
            </button>

            <button aria-label="copy this charts url" @click="copyUrl()"
                class="h-14 py-2 w-full text-sky-800 border border-sky-800 rounded-md  bg-white flex flex-col justify-center items-center">
                <div class="w-full flex items-center justify-center mt-2">
                    <div class="pl-3">

                        Copy this chart's link
                    </div>
                    <div class="w-10 flex items-center justify-center ">

                        <div v-if="!copied" class=" px-2  w-14 flex items-center justify-center">
                            <DocumentDuplicateIcon class="h-5 text-sky-800" />

                        </div>
                        <div v-if="copied" class=" px-2 w-14 flex items-center justify-center">
                            <CheckCircleIcon class="h-5 text-sky-800" />

                        </div>
                    </div>
                </div>
                <div
                    class="relative  opacity-45 flex items-center justify-center w-full text-sky-800 text-center  text-xxs font-medium">
                    {{ `${appUrl}/euro2025/${chart.chartId}` }}

                </div>
            </button>


            <button aria-label="make another chart" @click="chart.clearChartState(); ui.scrollToSection('home-create-section')"
                class=" h-14 shadow-lg  w-full rounded-md bg-white text-sky-800  border border-sky-800 flex items-center justify-center">
                <span class="">

                    Make another chart
                </span>
            </button>
        </div>
    </div>
</template>
