<script setup>

import { ref } from 'vue'

import { useChartStore } from '../../stores/chart.js'
const chart = useChartStore()

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

function formatDate(datetime) {
    return new Intl.DateTimeFormat(language.uiLang, {
        dateStyle: "medium",
        timeStyle: "short"
    }).format(new Date(datetime))
}

import { IconTrash, IconCheck, IconX } from '@tabler/icons-vue'

const deleteConfirm = ref(false)

const props = defineProps({
    item: Object
})

</script>

<template>


    <div class="relative border  border-sky-800 rounded-md shadow-lg flex w-full text-sm text-sky-800"
        :class="item.chartId === chart.chartId ? 'bg-sky-800 text-white' : ''">
        <button aria-label="select this chart" @click="chart.setChartState(item); ui.closeRightTray(); ui.scrollToTop()"
            class="w-full flex flex-col items-center justify-start p-3 space-y-1 overflow-hidden">
            <div class="flex w-full font-light items-start justify-start">
                <div class="w-1/3 text-left">
                    Created:
                </div>
                <div class="ml-2 w-full text-left font-medium">

                    {{ formatDate(item.createdAt) }}
                    <!-- {{ item.createdAt }} -->
                </div>
            </div>
            <div class="flex w-full font-light items-start justify-start">
                <div class="w-1/3 text-left">
                    Language:
                </div>
                <div class="ml-2 w-full text-left font-medium">

                    {{ item.chartLanguage }}
                </div>
            </div>
            <div class="flex w-full font-light items-start justify-start">
                <div class="w-1/3 text-left">
                    Timezone:
                </div>
                <div class="ml-2 w-full text-left font-medium overflow-hidden">

                    {{ item.chartTimezone }}
                </div>
            </div>
            <div v-if="item.makerName" class="flex w-full font-light items-start justify-start">
                <div class="w-1/3 text-left">
                    For:
                </div>
                <div class="ml-2 w-full text-left font-medium">

                    {{ item.makerName }}
                </div>
            </div>

            <div v-if="item.makerMessage" class="flex w-full font-light items-start justify-start">
                <div class="w-1/3 text-left">
                    Message:
                </div>
                <div class="ml-2 w-full text-left font-medium">

                    {{ item.makerMessage }}
                </div>
            </div>
            <div v-if="item.makerUrl" class="flex w-full font-light items-start justify-start">
                <div class="w-1/3 text-left">
                    QR Url:
                </div>
                <div class="ml-2 w-full text-left font-medium">

                    {{ item.makerUrl }}
                </div>
            </div>
        </button>
        <span class="border-r opacity-30 my-2"
            :class="item.chartId === chart.chartId ? 'border-white' : 'border-sky-800 '"></span>
        <button aria-label="delete this chart from local storage" @click="deleteConfirm = true" class="w-12  p-3">
            <IconTrash />

        </button>
        <div v-if="deleteConfirm" class="absolute w-full h-full border-2 border-red-600 text-sky-800  bg-white opacity-95 rounded-md shadow-lg flex text-sm items-center justify-evenly">
            <button aria-label="delete confirm" class="h-full  w-1/5 flex items-center justify-center" @click="chart.deleteChart(item.chartId)"><IconCheck class="h-8 w-8"/></button>
            <div class="font-medium  text-center">Delete this chart?</div>
            <button  aria-label="reject delete" class="h-full  w-1/5 flex items-center justify-center" @click="deleteConfirm = false"><IconX class="h-8 w-8"/></button>
        </div>
    </div>

</template>