import { ref } from "vue"
import { defineStore } from "pinia"

export const useChartRefsStore = defineStore('chartRefsStore', () => {

    const bracket = ref(null)
    const schedule = ref(null)
    const facts = ref(null)
    const games = ref(null)


    return {
        bracket,
        schedule,
        facts,
        games
    }
})