<script setup>

import { ref } from 'vue'

import MSFBanner from '../components/MSFBanner.vue'
import BracketChart from '../components/charts/BracketChart.vue'
import ScheduleChart from '../components/charts/ScheduleChart.vue'

import EmailSubscriber from '@shared/components/EmailSubscriber.vue'

const frontChart = ref(false)
const showFront = ref(true)

setInterval(() => {
  showFront.value = !showFront.value
  setTimeout(() => {
    frontChart.value = showFront.value
  }, 900) // Delay z-index swap to let opacity transition finish
}, 5000) // Full cycle every 2s (1s fade, 1s hold)


</script>


<template>
  <h1 class=" w-full  flex flex-col items-center justify-start  max-w-2xl px-2 mt-16 text-sky-800">
    <div class="w-full text-2xl text-center">Retro tournament wallcharts.</div>
    <div class="w-full text-4xl text-center bg-white ">
      Your <span class="font-extrabold">timezone</span> and <span class="font-extrabold">language.</span>
    </div>

    <div id="chart-stack" class="w-96 mt-12 relative h-52">
      <ScheduleChart :style="{ zIndex: frontChart ? 10 : 0 }"
        :class="frontChart ? 'opacity-100 z-10' : 'opacity-40 z-0'"
        class=" absolute top-0 right-8 w-80 border bg-white border-sky-800 shadow-lg rounded-md rotate-3  transition-opacity duration-1000 ease-in-out" />
      <BracketChart :style="{ zIndex: frontChart ? 0 : 10 }" :class="frontChart ? 'opacity-40 z-0' : 'opacity-100 z-10'"
        class=" absolute top-0 right-8 w-80 border bg-white border-sky-800 shadow-lg rounded-md  -rotate-3 z-10  transition-opacity duration-1000 ease-in-out" />

    </div>


    <div class="w-full text-2xl text-center mt-16 font-bold">
      Customisable. Print-at-home. Free.
    </div>
    <MSFBanner class="mt-4" />


  </h1>
  <div class="w-full bg-sky-800 text-white mt-12 flex flex-col items-center justify-start pb-6 px-2">
    <div class="w-full flex max-w-2xl  flex-col items-center justify-start space-y-3">

      <div class="w-full text-xl text-center mt-8 font-medium">Current tournament:</div>
      <router-link :to="{ name: 'euro2025' }" aria-label="preview your chart"
        class=" h-14 shadow-lg flex items-center justify-center font-extrabold text-xl w-full sm:w-4/5 rounded-md   bg-white text-sky-800  ">
        Women's Euro 2025
      </router-link>
    </div>
    <div class="w-full text-xl text-center mt-8">Upcoming previews:</div>
    <div class="w-full flex max-w-2xl  flex-col items-center justify-start space-y-3">

      <router-link :to="{ name: 'afc2025' }" aria-label="preview your chart"
        class="!mt-4 h-14 shadow-lg flex items-center justify-center font-normal text-xl w-full sm:w-4/5 rounded-md    border border-white ">
        Men's African Cup 2025/26
      </router-link>
      <router-link :to="{ name: 'wc2026' }" aria-label="preview your chart"
        class="!mt-4 h-14 shadow-lg flex items-center justify-center font-normal text-xl w-full sm:w-4/5 rounded-md    border border-white ">
        Men's World Cup 2026
      </router-link>
      <router-link :to="{ name: 'ac2027' }" aria-label="preview your chart"
        class="!mt-4 h-14 shadow-lg flex items-center justify-center font-normal text-xl w-full sm:w-4/5 rounded-md    border border-white ">
        Men's Asian Cup 2027
      </router-link>
      <router-link :to="{ name: 'wc2027' }" aria-label="preview your chart"
        class="!mt-4 h-14 shadow-lg flex items-center justify-center font-normal text-xl w-full sm:w-4/5 rounded-md    border border-white ">
        Women's World Cup 2027
      </router-link>
    </div>

  </div>
  <div class="w-full flex max-w-2xl  flex-col items-center justify-start space-y-3 px-2 sm:px-4">

    <div class=" text-xl mt-14 font-bold  w-full sm:w-4/5 text-sky-800">Keep up to date:</div>
    <EmailSubscriber class=" mt-4 w-full sm:w-4/5 " />
  </div>



</template>
