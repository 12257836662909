<script setup>
import { RouterView } from "vue-router"
import { onMounted, watch } from 'vue'

import { useRoute } from "vue-router"
const route = useRoute()



import TopNav from './components/layout/TopNav.vue'
import Footer from './components/layout/Footer.vue'
import RightTray from '@shared/components/RightTray.vue'

import FlashMessage from '@shared/components/FlashMessage.vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { useLocationStore } from '@shared/stores/location.js'
const location = useLocationStore()

import { useVersionStore } from './stores/version.js'
const version = useVersionStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

import { useSubscriberStore } from '@shared/stores/subscriber.js'
const subscriber = useSubscriberStore()

import { useChartStore } from './stores/chart.js'
const chart = useChartStore()

import { useScheduleStore } from './stores/schedule.js'
const schedule = useScheduleStore()


onMounted(() => {
  //reviewed and updated 2025
  version.checkAndUpdateVersion()
  language.getUiLanguage()
  chart.getChartLanguage()
  location.getLocation()
  chart.generateShareQRCode()
  subscriber.checkSubscriber()
  schedule.localiseGameSchedule()
})

</script>


<template>
  <header>
    <div>
    </div>
  </header>

  <div class="w-full" :lang="language.uiLang">
    <RightTray class="z-30" :rightTrayIsOpen="ui.rightTrayIsOpen" :openRightTray="ui.openRightTray"
      :closeRightTray="ui.closeRightTray" :rightTrayComponent="ui.rightTrayComponent"
      :rightTrayComponentProps="ui.rightTrayComponentProps" :rightTrayBackNavComponent="ui.rightTrayBackNavComponent"
      :rightTrayBackNavProps="ui.rightTrayBackNavProps" />
  </div>

  <div id="layout" class="flex flex-col w-full  justify-start items-center h-full " :lang="language.uiLang">
    <div class="w-full flex flex-col  justify-start">


      <div id="app-top-bar" class="w-full">
        <TopNav />
      </div>

      <div v-if="chart.chartGetInProgress"
        class="w-full h-96 flex flex-col items-center justify-center text-2xl text-sky-800">

        <div id="load-circles" class="w-full flex items-center justify-center space-x-3 ">

          <div class=" h-5 w-5 bg-sky-800 opacity-90 rounded-full animate-pulse " style="animation-delay: 0s">
          </div>
          <div class="  h-5 w-5 bg-sky-800 opacity-90 rounded-full animate-pulse " style="animation-delay: 0.4s">
          </div>
          <div class=" h-5 w-5 bg-sky-800 opacity-90 rounded-full animate-pulse " style="animation-delay: 0.8s">
          </div>
        </div>
        <div class="mt-8">

          Loading your chart..
        </div>
      </div>
      <div id="main-content" v-if="!chart.chartGetInProgress"
        class="max-w-4/5 flex flex-col justify-start items-center mt-8 ">
        <RouterView />
      </div>
    </div>

    <div id="footer-bar" class="w-full  mt-32">
      <Footer />
    </div>
  </div>
  <transition enter-active-class="transition-opacity ease-in-out duration-500" enter-from-class="opacity-0"
    enter-to-class="opacity-100" leave-active-class="transition-opacity ease-in duration-1000"
    leave-from-class="opacity-100" leave-to-class="opacity-0">


      <FlashMessage  />

  </transition>



</template>
