<script setup>
import { ref } from 'vue'

import { useChartStore } from '../../stores/chart.js'
const chart = useChartStore()

import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline'


</script>

<template>
    <div v-if="chart.makerUrl && chart.checkInProgress"
        class="w-full py-2 max-h-32 sm:max-h-20 overflow-y-clip flex shadow-md items-center justify-center text-sky-800 border-b border-l border-r border-sky-800 rounded-br-md rounded-bl-md">

        <div v-if="chart.isFetching" class="space-y-2 w-full pb-2">
            <div class="text-xs w-full text-center">

                Validating URL...
            </div>
            <div id="load-circles" class="w-full flex items-center justify-center space-x-3">

                <div class=" h-3 w-3 bg-sky-800 opacity-90 rounded-full animate-pulse " style="animation-delay: 0s">
                </div>
                <div class="  h-3 w-3 bg-sky-800 opacity-90 rounded-full animate-pulse " style="animation-delay: 0.4s">
                </div>
                <div class=" h-3 w-3 bg-sky-800 opacity-90 rounded-full animate-pulse " style="animation-delay: 0.8s">
                </div>
            </div>
        </div>

        <div v-if="!chart.isFetching && chart.makerUrl.length && chart.checkInProgress" class="text-xs">
            <div v-if="chart.isValidUrl" class=" flex w-full items-center justify-evenly">
                <CheckIcon class="h-5 w-5 ml-2 text-green-600" />
                <div class="ml-4 w-full overflow-y-scroll pr-2">
                    <strong>{{ chart.siteTitle }}</strong>

                    <p class="">{{ chart.siteDescription }}</p>
                </div>
            </div>
            <div v-if="!chart.isValidUrl && chart.checkInProgress" class="p-2   flex w-full items-center justify-evenly">
                <XMarkIcon class="h-5 w-5 ml-2 text-red-600" />
                <div class="w-full text-sm ml-4">

                    <p>

                        Couldn’t validate this site. It may be protected by a visitor challenge or be wrong.
                    </p>
                    <p>
                        Please <strong>triple-check </strong>your URL and test the QR scan by previewing your chart before creation.
                    </p>

                </div>
            </div>
        </div>
    </div>
</template>
