export function apiError(error, ui) {
    if (error.response) {
        if (error.response.status === 422) {
            ui.showMessage({ message: error.response.data.error_message, isError: true })
            return
        }
        ui.showMessage({ message: error.response.data.detail, isError: true })
    } else if ( error.request) {
        if (error.message.includes('Network Error')) {
            console.log('Server is unreachable, possibly down.');
            ui.showMessage({ message: "The server is unreachable 😱", isError: true })
        } else {
            ui.showMessage({ message: "Request Error", isError: true })
        }
    }
}