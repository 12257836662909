<script setup>

import { ref, onMounted, watch } from 'vue'

import { useChartStore } from '../../stores/chart.js'
const chart = useChartStore()

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

import { IconTrash } from '@tabler/icons-vue'

import ChartListCard from './ChartListCard.vue'

onMounted(() => {
    chart.getAllCharts()
})

watch(() => chart.chartId, (newVal, oldVal) => {
    chart.getAllCharts()
})






</script>

<template>
    <div class="w-full flex flex-col space-y-4 pb-8">
        <div class="w-full text-3xl text-sky-800 font-bold text-center">Your Charts</div>
        <div v-if="chart.chartList" class="w-full text-xs mt-1 text-sky-800 text-center">Charts are always available at the link you received when you made the chart. This list is for convenience, and is stored on this device only.</div>
        <div v-if="!chart.chartList" class="w-full text-center text-sky-800 font-medium">
            Charts you create or receive will appear here.
        </div>
        <div class="w-full  flex flex-col space-y-2 ">
            <ChartListCard v-for="item in chart.chartList" :key="item.chartId" :item="item"/>
        </div>
    </div>

</template>