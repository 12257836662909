<script setup>

import { markRaw } from 'vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import Privacy from '@shared/components/Privacy.vue'
import uiLangSelect from '@shared/components/uiLangSelect.vue'

import { LanguageIcon } from '@heroicons/vue/24/outline'


</script>


<template>
    <div id="footer-bar" class="w-full flex flex-col items-center justify-center px-4 pb-6 text-xs h-32">
        <div class="w-full flex items-center justify-center relative ">
            <LanguageIcon class="text-sky-800 h-6" />
            <uiLangSelect class="h-full ml-2"/>
            <div class="absolute -top-2.5 w-9 h-4 rounded-md bg-blue-500 text-white text-xxs text-center flex justify-center items-center">BETA</div>
        </div>
        <div class="font-bold mt-8 text-sky-800">wallcharts.org is free from:</div>
        <button @click="ui.openRightTray(markRaw(Privacy))" class="flex items-center justify-center space-x-1 mt-1 text-sky-800">
            <span class="">Cookies</span>
            <span class="">/</span>
            <span class="">Ads</span>
            <span class="">/</span>
            <span class="">Tracking</span>
            <span class="">/</span>
            <span class="">Paywalls</span>
            <span class="">/</span>
            <span class="">AI</span>
        </button>
        <button class="underline ml-1 text-xxs w-full text-sky-800 mt-4"
                @click="ui.openRightTray(markRaw(Privacy))">Terms of Use and Privacy Policy</button>

    </div>
</template>

