<script setup>

import heroContent from '../content/heroContent.js'

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

import { useChartStore } from '../stores/chart.js'
const chart = useChartStore()

</script>

<template>

    <div class=" flex flex-col w-full  items-center justify-center">

        <div class="text-2xl text-sky-800 w-full flex items-center justify-center">

            <span v-if="chart.makerName">

                {{ chart.makerName }}
            </span>
            <span v-else>Your customised</span>
        </div>
        <div id="HeroMidLine"
            class=" text-4xl sm:text-5xl items-center  justify-center text-center text-sky-800 font-extrabold"
            :aria="language.getAriaElement(heroContent, 'HeroMidLine')">
            {{ language.getContentElement(heroContent, 'HeroMidLine') }}
        </div>

        <div class="w-full text-sm italic text-center">


        </div>





    </div>

</template>
