<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue'
import { useLocationStore } from '../stores/location.js'
const location = useLocationStore()

import ThreeDots from './ThreeDots.vue'
import { ArrowUpOnSquareIcon, EnvelopeIcon, TruckIcon, ArrowDownCircleIcon, LanguageIcon, ChatBubbleLeftIcon, GlobeEuropeAfricaIcon, MapPinIcon, LinkIcon, XCircleIcon, ChevronDownIcon } from '@heroicons/vue/24/outline'

const regionSelectIsOpen = ref(false)
const regionSelectRef = ref(null)
const regionListRef = ref(null)
const selectedOptionRef = ref(null)

const toggleRegionSelect = async () => {
    regionSelectIsOpen.value = !regionSelectIsOpen.value

    if (regionSelectIsOpen.value) {
        await nextTick()

        // Find the index of the selected region
        const selectedIndex = location.regions.findIndex(
            region => region === location.selectedRegion
        )

        if (selectedIndex >= 0 && regionListRef.value) {
            // Calculate approximate scroll position based on item height
            const itemHeight = 36 // height of each item including padding
            const containerHeight = regionListRef.value.clientHeight
            const scrollPosition = (itemHeight * selectedIndex) - (containerHeight / 2) + (itemHeight / 2)

            // Set scroll position immediately
            regionListRef.value.scrollTop = Math.max(0, scrollPosition)
        }
    }
}

const setSelectedOptionRef = (el, region) => {
    if (el && region === location.selectedRegion) {
        selectedOptionRef.value = el
    }
}

const handleClickOutside = (event) => {
    if (regionSelectRef.value && !regionSelectRef.value.contains(event.target)) {
        regionSelectIsOpen.value = false
    }
}

onMounted(() => {
    document.addEventListener("click", handleClickOutside)
})

onUnmounted(() => {
    document.removeEventListener("click", handleClickOutside)
})
</script>

<template>
    <div ref="regionSelectRef" class="relative w-1/2 h-10">
        <button @click="regionSelectIsOpen = false" v-if="regionSelectIsOpen"
            class="fixed top-0 h-full w-full z-10"></button>

        <button @click="toggleRegionSelect" id="chart-lang-select"
            class="shadow-md w-full flex items-center justify-evenly space-x-2 h-10 sm:mr-0 border pl-2 pr-3 border-sky-800 bg-white rounded-md focus:outline-none text-base">
            <GlobeEuropeAfricaIcon class="w-5 min-w-5 sm:w-8 sm:mr-1 text-sky-700" />
            <div v-if="location.selectedTimezone"
                class="text-sky-800 text-nowrap text-left overflow-hidden w-full font-semibold">
                {{ location.selectedRegion }}
            </div>
            <ThreeDots v-if="!location.selectedTimezone" class="w-full"/>
            <ChevronDownIcon class="h-4 text-sky-800" />
        </button>

        <div v-if="regionSelectIsOpen" ref="regionListRef"
            class="absolute z-20 -top-24 bg-white w-48 max-h-96 overflow-y-scroll space-y-1.5 flex flex-col rounded-md border border-sky-800 shadow-lg">
            <button v-for="region in location.regions" :key="region" :value="region"
                ref="(el) => setSelectedOptionRef(el, region)"
                class="hover:bg-sky-800 hover:text-white text-sky-800 w-full pl-2 py-1 text-left"
                :class="location.selectedRegion === region ? 'text-white font-bold bg-sky-800' : ''"
                @click="toggleRegionSelect(); location.selectedRegion = region">
                {{ region }}
            </button>
        </div>
    </div>
</template>