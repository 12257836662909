<script setup>

import heroContent from '../content/heroContent.js'

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

</script>

<template>

    <div class="flex flex-col w-full  items-center justify-center">
        <div id='HeroTopLine2'
            class=" text-lg font-medium sm:text-xl items-center  justify-center text-center text-sky-800 "
            :aria="language.getAriaElement(heroContent, 'HeroTopLine2')">
            {{ language.getContentElement(heroContent, 'HeroTopLine2') }}
        </div>

        <div id="HeroMidLine"
            class=" text-5xl sm:text-6xl  items-center justify-center text-center text-sky-800 font-extrabold"
            :aria="language.getAriaElement(heroContent, 'HeroMidLine')">
            {{ language.getContentElement(heroContent, 'HeroMidLine') }}
        </div>

        <div id='HeroTopLine'
            class="mt-1 text-lg font-medium sm:text-xl items-center  justify-center text-center text-sky-800 "
            :aria="language.getAriaElement(heroContent, 'HeroTopLine')">
            {{ language.getContentElement(heroContent, 'HeroTopLine') }}
        </div>



    </div>

</template>

