<template>
    <div class="flex flex-col items-center justify-start w-full">

        <div class="w-full mt-20 text-xl sm:text-2xl flex justify-center items-center  text-sky-800 font-medium">
            Taking you to Médecins Sans Frontières.org
        </div>
        <div id="load-circles" class="w-full flex items-center justify-center space-x-3 mt-6">

            <div class=" h-5 w-5 bg-sky-800 opacity-90 rounded-full animate-pulse " style="animation-delay: 0s">
            </div>
            <div class="  h-5 w-5 bg-sky-800 opacity-90 rounded-full animate-pulse " style="animation-delay: 0.4s">
            </div>
            <div class=" h-5 w-5 bg-sky-800 opacity-90 rounded-full animate-pulse " style="animation-delay: 0.8s">
            </div>
        </div>
    </div>
</template>

<script setup>

import { onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { useChartStore } from '../stores/chart.js'
const chart = useChartStore()

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

const router = useRouter()
const route = useRoute()

onMounted(() => {
    console.log("fire off Send Beacon here")
    window.location.href = "https://msf.org"

})


</script>