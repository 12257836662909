import axios from "axios"


const wallchartsApi = axios.create({
    baseURL: import.meta.env.VITE_SERVER_ROOT,
    timeout: 30_000
})


export default {
    async postCreateChart(payload) {
        return wallchartsApi.post(
            "/wallcharts/charts",
            {
                'scheduleHTML': payload.scheduleHTML,
                'bracketHTML': payload.bracketHTML,
                'chartTimezone': payload.chartTimezone,
                'chartLanguage': payload.chartLanguage,
                'chartId': payload.chartId,
                'makerName': payload.makerName,
                'makerUrl': payload.makerUrl,
                'makerMessage': payload.makerMessage
            },
            {
                headers: {
                    'x-app-id': import.meta.env.VITE_APP_ID,
                    'Content-Type': 'application/json'
                }
            }
        )
    },
    async getChart(chartId) {
        return wallchartsApi.get(
            `/wallcharts/charts/${chartId}`,
            {
                headers: {
                    'x-app-id': import.meta.env.VITE_APP_ID,
                    'Content-Type': 'application/json'
                }
            }
        )
    },


}
