export const translatedTeams = {
  'SWITZERLAND': {
      da: "SCHWEIZ",
      nl: "ZWITSERLAND",
      en: "SWITZERLAND",
      fi: "SVEITSI",
      fr: "SUISSE",
      de: "SCHWEIZ",
      is: "SVISS",
      it: "SVIZZERA",
      no: "SVEITS",
      pl: "SZWAJCARIA",
      pt: "SUÍÇA",
      es: "SUIZA",
      sv: "SCHWEIZ",
      cy: "SWISTIR"
  },
  'NORWAY': {
      da: "NORGE",
      nl: "NOORWEGEN",
      en: "NORWAY",
      fi: "NORJA",
      fr: "NORVÈGE",
      de: "NORWEGEN",
      is: "NOREG",
      it: "NORVEGIA",
      no: "NORGE",
      pl: "NORWEGIA",
      pt: "NORUEGA",
      es: "NORUEGA",
      sv: "NORGE",
      cy: "NORWY"
  },
  'ICELAND': {
      da: "ISLAND",
      nl: "IJSLAND",
      en: "ICELAND",
      fi: "ISLANTI",
      fr: "ISLANDE",
      de: "ISLAND",
      is: "ÍSLAND",
      it: "ISLANDA",
      no: "ISLAND",
      pl: "ISLANDIA",
      pt: "ISLÂNDIA",
      es: "ISLANDIA",
      sv: "ISLAND",
      cy: "ISLANDA"
  },
  'FINLAND': {
      da: "FINLAND",
      nl: "FINLAND",
      en: "FINLAND",
      fi: "SUOMI",
      fr: "FINLANDE",
      de: "FINNLAND",
      is: "FINNLAND",
      it: "FINLANDIA",
      no: "FINLAND",
      pl: "FINLANDIA",
      pt: "FINLÂNDIA",
      es: "FINLANDIA",
      sv: "FINLAND",
      cy: "Y FFINDIR"
  },
  'SPAIN': {
        da: "SPANIEN",
        nl: "SPANJE",
        en: "SPAIN",
        fi: "ESPANJA",
        fr: "ESPAGNE",
        de: "SPANIEN",
        is: "SPÁNN",
        it: "SPAGNA",
        no: "SPANIA",
        pl: "HISZPANIA",
        pt: "ESPANHA",
        es: "ESPAÑA",
        sv: "SPANIEN",
        cy: "SBAEN"
    },
    'PORTUGAL': {
        da: "PORTUGAL",
        nl: "PORTUGAL",
        en: "PORTUGAL",
        fi: "PORTUGALI",
        fr: "PORTUGAL",
        de: "PORTUGAL",
        is: "PORTÚGAL",
        it: "PORTOGALLO",
        no: "PORTUGAL",
        pl: "PORTUGALIA",
        pt: "PORTUGAL",
        es: "PORTUGAL",
        sv: "PORTUGAL",
        cy: "PORTIWGAL"
    },
    'BELGIUM': {
        da: "BELGIEN",
        nl: "BELGIË",
        en: "BELGIUM",
        fi: "BELGIA",
        fr: "BELGIQUE",
        de: "BELGIEN",
        is: "BELGÍA",
        it: "BELGIO",
        no: "BELGIA",
        pl: "BELGIA",
        pt: "BÉLGICA",
        es: "BÉLGICA",
        sv: "BELGIEN",
        cy: "BELG"
    },
    'ITALY': {
        da: "ITALIEN",
        nl: "ITALIË",
        en: "ITALY",
        fi: "ITALIA",
        fr: "ITALIE",
        de: "ITALIEN",
        is: "ÍTALÍA",
        it: "ITALIA",
        no: "ITALIA",
        pl: "WŁOCHY",
        pt: "ITÁLIA",
        es: "ITALIA",
        sv: "ITALIEN",
        cy: "YR EIDAL"
    },
    'GERMANY': {
        da: "TYSKLAND",
        nl: "DUITSLAND",
        en: "GERMANY",
        fi: "SAKSA",
        fr: "ALLEMAGNE",
        de: "DEUTSCHLAND",
        is: "ÞÝSKALAND",
        it: "GERMANIA",
        no: "TYSKLAND",
        pl: "NIEMCY",
        pt: "ALEMANHA",
        es: "ALEMANIA",
        sv: "TYSKLAND",
        cy: "YR ALMAEN"
    },
    'POLAND': {
        da: "POLEN",
        nl: "POLEN",
        en: "POLAND",
        fi: "PUOLA",
        fr: "POLOGNE",
        de: "POLEN",
        is: "PÓLLAND",
        it: "POLONIA",
        no: "POLEN",
        pl: "POLSKA",
        pt: "POLÓNIA",
        es: "POLONIA",
        sv: "POLEN",
        cy: "PWLONI"
    },
    'DENMARK': {
        da: "DANMARK",
        nl: "DENEMARKEN",
        en: "DENMARK",
        fi: "TANSKA",
        fr: "DANEMARK",
        de: "DÄNEMARK",
        is: "DANMÖRK",
        it: "DANIMARCA",
        no: "DANMARK",
        pl: "DANIA",
        pt: "DINAMARCA",
        es: "DINAMARCA",
        sv: "DANMARK",
        cy: "DENMARCMARC"
    },
    'SWEDEN': {
        da: "SVERIGE",
        nl: "ZWEDEN",
        en: "SWEDEN",
        fi: "RUOTSI",
        fr: "SUÈDE",
        de: "SCHWEDEN",
        is: "SVÍÞJÓÐ",
        it: "SVEZIA",
        no: "SVERIGE",
        pl: "SZWECJA",
        pt: "SUÉCIA",
        es: "SUECIA",
        sv: "SVERIGE",
        cy: "SWEAD"
    },
    'FRANCE': {
        da: "FRANKRIG",
        nl: "FRANKRIJK",
        en: "FRANCE",
        fi: "RANSKA",
        fr: "FRANCE",
        de: "FRANKREICH",
        is: "FRAKKLAND",
        it: "FRANCIA",
        no: "FRANKRIKE",
        pl: "FRANCJA",
        pt: "FRANÇA",
        es: "FRANCIA",
        sv: "FRANKRIKE",
        cy: "FFRAINC"
    },
    'ENGLAND': {
        da: "ENGLAND",
        nl: "ENGELAND",
        en: "ENGLAND",
        fi: "ENGLANTI",
        fr: "ANGLETERRE",
        de: "ENGLAND",
        is: "ENGLAND",
        it: "INGHILTERRA",
        no: "ENGLAND",
        pl: "ANGLIA",
        pt: "INGLATERRA",
        es: "INGLATERRA",
        sv: "ENGLAND",
        cy: "LLOEGR"
    },
    'WALES': {
        da: "WALISEN",
        nl: "WALES",
        en: "WALES",
        fi: "WALES",
        fr: "PAYS DE GALLES",
        de: "WALES",
        is: "WALES",
        it: "GALLES",
        no: "WALES",
        pl: "WALIA",
        pt: "PAÍS DE GALES",
        es: "GALES",
        sv: "WALES",
        cy: "CYMRU"
    },
    'NETHERLANDS': {
        da: "HOLLAND",
        nl: "NEDERLAND",
        en: "NETHERLANDS",
        fi: "ALANKOMAAT",
        fr: "PAYS-BAS",
        de: "NIEDERLANDE",
        is: "HOLLAND",
        it: "PAESI BASSI",
        no: "NEDERLAND",
        pl: "HOLANDIA",
        pt: "PAÍSES BAIXOS",
        es: "PAÍSES BAJOS",
        sv: "NEDERLÄNDERNA",
        cy: "YR IS-ELFYDD"
    }
}
